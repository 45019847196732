import { Injectable } from '@angular/core';
import { UserRoles } from '../shared/models/user-roles';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { Observable } from 'rxjs';
import { AlertService } from '../shared/services/index';
import { AlertTypes } from '../shared/models/index';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService {
    private timeOutStarted: Boolean = false;
    private timeoutCallback;
    private token = null;
    private tokenjson: any = {};
    private resumeSession: any = {};

    constructor(private router: Router, private appService: AppService, private alertService: AlertService, private http?: HttpClient) {

        // this.token = "bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb"; // pour test
    }
    public getHttpOptions(json = false, pdf = false): any {
        let ContentType;
        if (json) {
            ContentType = 'application/json';
        } else {
            ContentType = 'application/x-www-form-urlencoded';
        }

        if (pdf) {
            const headers = new HttpHeaders({
                'Content-Type': ContentType,
                'Accept': 'application/pdf',
                'X-das-language': this.appService.getLanguageAPI(),
                'X-das-token': this.getToken()
            });
            const options = {
                headers: headers,
                responseType: 'blob'
            };
            return options;
        } else {
            const headers = new HttpHeaders({
                'Content-Type': ContentType,
                'X-das-language': this.appService.getLanguageAPI(),
                'X-das-token': this.getToken()
            });
            const options = {
                headers: headers,
                withCredentials: true
            };
         return options;
        }
    }
    public logout() {
        sessionStorage.clear();
        localStorage.clear();
        clearTimeout(this.timeoutCallback);
        this.timeOutStarted = false;
    }

    public verifyToken(): Observable<Object> {
        const options = {withCredentials: true};
        return this.http.get(environment.apiUrl + '/api/tokens/secure-token', options).map((secureToken) => {
            this.tokenjson = secureToken;
            if (this.tokenjson !== null &&
                this.tokenjson !== undefined &&
                this.tokenjson !== '' && this.tokenjson !== 'null') { // MBR0901-1401

                this.setToken(this.tokenjson.token);
                this.appService.setLanguage(this.tokenjson.language);
                this.appService.action = this.tokenjson.action;
                this.appService.actionValue = this.tokenjson.value;
                this.appService.brokerName = this.tokenjson.webbroker;
                this.appService.brokerEmail = this.tokenjson.webbrokeremail;
                // console.log(JSON.stringify(this.mainActivity, null, "   "));
            }
            return secureToken;
          }).catch((err) => this.appService.handleError(err)); // MBR0901-1401
    }
    public setToken(token) {
        localStorage.setItem('secureToken', token);
        this.token = token;
    }
    public resumePMECalc(simID: string): Observable<Object> {
        return this.http.get(environment.apiUrl + '/api/simulations/' + this.appService.actionValue, this.getHttpOptions()).map((resume) => {
            this.resumeSession = resume;
            this.appService.simulationNumber = this.appService.actionValue;
            this.appService.trackQuestionId = this.resumeSession.trackQuestionId;
            this.appService.survey = this.resumeSession.survey; // MBR2610-1352
            this.appService.version = this.resumeSession.version; // MBR2610-1352
            // console.log(JSON.stringify(this.resumeSession, null, "   "));
            return resume;
        }).catch((err) => this.appService.handleError(err));
    }

    public returnToExtra(): void { // MBR2404-1241
        this.getTokenToExtra().subscribe((extranetToken) => {
            window.location.href = environment.extranetUrl;
        });
    }

    public createToken(
        json: {
            vatNumber: string, userId: string, cli: string, portfolioId: number, contributorId: number, clientId: number, language: string
        }
    ): Observable<Object> {
        return this.http.post(environment.apiUrl + '/api/belfius/simulations/authentification', json, this.getHttpOptions(true));
    }

    public createResumeToken(id: number): Observable<Object> {
        return this.http.get(`${environment.apiUrl}/api/belfius/simulations/${id.toString()}/results`, this.getHttpOptions(true));
    }

    public createSimulation(
        json: {
            vatNumber: string, userId: string, cli: string, portfolioId: number, contributorId: number, clientId: number, language: string
        }
    ): Observable<Object> {
        return this.http.post(environment.apiUrl + '/api/simulations', json, this.getHttpOptions(true));
    }

    public testOkta(): Observable<Object> {
        return this.http.get(environment.apiUrl + '/api/belfius/zgeg/testAuthentification', this.getHttpOptions(true));
    }

    private getTokenToExtra(): Observable<Object> { // MBR2404-1241
        const options: any = this.getHttpOptions(true);
        return this.http.get(environment.apiUrl + '/api/tokens/extranet-token', options).pipe(
            catchError((err) => this.appService.handleError(err))
        );
    }

    private getToken() {
        /* TEST */
        if (this.token === '' || this.token === null || this.token === undefined) {
            // this.token = localStorage.getItem("secureToken");
            // if (this.token === '' || this.token === null || this.token === undefined) {
                this.token = 'bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb'; // pour tests
            // }
        }
        /* TEST */
        // console.log(this.token);
        return this.token;
    }
/* MBR0901-1401
    private verifyTokenError(error: Response | any) { // add by MBR

      //  this.alertService.sendAlert("Get secure token failed", AlertTypes.WARNING, false);
       // this.router.navigateByUrl("https://extranet.das.be");

      //  return Observable.throw("error");

        // this.router.navigate(['/risk-calculator']);
        // this.router.navigateByUrl("http://localhost/Extranet?action=PMETOPOL");
        return this.appService.handleError(error);

    }
*/
}
