import { Component, Input, OnInit } from "@angular/core";
import { Validations } from '../../../validations';
import { CustomTranslateService } from "../../services/index";
import { FormControl } from "@angular/forms";

@Component({
    selector: 'as-input-validation',
    template: `<div class="form-group form-input-group" [ngClass]="{'has-error':errorMessage}"> 
                  <label *ngIf="labelText!==''" class="form-label">{{labelText}}</label>
                  <div class="full-width form-input-flex">                    
                        <ng-content></ng-content>                                        
                        <div class="alert alert-danger" *ngIf="errorMessage">
                            {{errorMessage}} 
                        </div>                     
                  </div>                 
             </div>`,
    styleUrls: ['input-validation.component.scss']
})
export class InputValidationComponent implements OnInit {

    @Input()
    private value: any;
    @Input()
    private singleControl: FormControl;

    @Input()
    private errorValues: any;

    public labelText: string = "";
    public errorMessage = '';
    constructor(
        private translate: CustomTranslateService
    ) { }

    public ngOnInit() {

        this.singleControl.valueChanges.subscribe((s) => {
            if (this.singleControl.dirty) {
                let errors: any = this.singleControl.errors;
                this.errorMessage = '';
                if (errors) {
                    const messages = new Validations(this.translate).validationMessages['default'];
                    Object.keys(messages).some((key) => {
                        if (errors[key]) {
                            this.errorMessage = messages[key];
                            return true;
                        }
                    });
                }
            }
        });
    }
}
