import { Injectable } from '@angular/core';
import { NgbdAlertComponent } from './alert.component';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Rx';
import { Alert, AlertTypes } from './../models';
import { Question } from '../models/question';
import { CustomTranslateService } from './index';
import { AuthService } from '../../auth/index';
import { AppService } from '../../app.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class WizardService {

    public nextQuestionInCategoryId = 0;
    public prevQuestionInCategoryId = 0;
    public nextQuestion: any = null;
    public sessionId: string;
    private questions: any = [];
    private currentCategory = 0;
    private currentQuestionInCategory = 0;
    private goingBack = false;
    private fadeInProgress = false;

    constructor(
        private translate: CustomTranslateService,
        private appService: AppService,
        private authService: AuthService,
        private http: HttpClient) {
        this.translate = translate;
    }

    public getNewSessionId(): string {

        // Fetch sessionId in back-end

        // Generate sample uuid
        this.sessionId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line:no-bitwise
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
            return v.toString(16);
        });
        return this.sessionId;
    }
    public hasCurrentSession(): boolean {

        const first = this.sessionId !== null && this.sessionId !== undefined && this.sessionId !== '';
        if (this.questions.length === 0) {
            return false;
        }
        return first;
    }
    public getAllQuestions() {
        return this.questions;
    }
    public getQuestionAnswer(id: string): void {
        const simId = this.appService.simulationNumber;
        this.http.get(environment.apiUrl + '/api/simulations/' + simId + '/questions/' + id, this.authService.getHttpOptions())
            .subscribe((e: any) => {
                switch (e.type) {
                    case 'proSelect' : // progess select
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].selectedValue = [];
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].type = 1;
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].userInput = e.userInput;
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].values = e.values;

                        for (const i of e.values) {
                            if (i.selected) {
                                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].selectedValue.push(i);
                            }
                        }

                        break;
                    case 'siSelect' : // single select
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].selectedValue = [];
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].type = 2;
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].userInput = e.userInput;
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].values = e.values;
                        for (const i of e.values) {
                            if (i.selected) {
                                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].selectedValue.push(i);
                            }
                        }
                        break;
                    case 'integer' : // integer
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].type = 3;
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].userInput = e.userInput;
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].values = e.values;
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].selectedValue = e.userInput;
                        break;
                    case 'muSelect' : // multiSelect
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].type = 4;
                        for (const i of e.values) {
                            if (i.selected) {
                                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].selectedValue.push(i);
                            }
                        }
                        break;
                    case 'building' : // building
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].type = 5;
                        break;
                    }
            }, (err) => {
                this.appService.handleError(err);
            });
    }
    public setQuestionAnswer(e): void {
        switch (e.type) {
            case 'proSelect' : // progess select
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].selectedValue = [];
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].type = 1;
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].userInput = e.userInput;
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].values = e.values;

                for (const i of e.values) {
                    if (i.selected) {
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].selectedValue.push(i);
                    }
                }

                break;
            case 'siSelect' : // single select
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].selectedValue = [];
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].type = 2;
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].values = e.values;
                for (const i of e.values) {
                    if (i.selected) {
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].selectedValue.push(i);
                    }
                }
                break;
            case 'integer' : // integer
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].type = 3;
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].userInput = e.userInput;
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].values = e.values;
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].selectedValue = e.userInput;
                break;
            case 'muSelect' : // multiSelect
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].selectedValue = [];
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].values = e.values;
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].type = 4;
                for (const i of e.values) {
                    if (i.selected) {
                        this.questions[this.currentCategory].questions[this.currentQuestionInCategory].selectedValue.push(i.text);
                    }
                }
                break;
            case 'building' : // building
                this.questions[this.currentCategory].questions[this.currentQuestionInCategory].type = 5;

                break;
        }
    }
    public getQuestions(): Observable<Object> {

        const simId = this.appService.simulationNumber;
        // console.log(simId);
        return this.http.get(environment.apiUrl + '/api/simulations/' + simId + '/questions', this.authService.getHttpOptions()).map((Q: any) => {
            const e = Q;
            // console.log(JSON.stringify(e, null, "   "));
            const sampleQuestions: any[] = [];
            let category: any = {};
            category.name = 'GENERAL';
            category.questions = [];
            let question: any = {};

            for (let i = 0; i < e.length; i++) {

                const questionCat = e[i].category;
                const tempQuestion = e[i];
                if (questionCat !== category.name) { // We have a new category
                    sampleQuestions.push(category);
                    category = {};
                    category.name = questionCat;
                    category.questions = [];
                }
                question = {};
                switch (tempQuestion.type) {
                    case 'integer':
                        question.id = tempQuestion.id;
                        question.type = 3;
                        question.title = tempQuestion.title;
                        question.titleFr = tempQuestion.titleFr;
                        question.titleNl = tempQuestion.titleNl;
                        question.onlyNumbers = true;
                        question.helptext = tempQuestion.helpText;
                        question.helptextFr = tempQuestion.helpTextFr;
                        question.helptextNl = tempQuestion.helpTextNl;
                        category.questions.push(question);
                        break;
                    case 'muSelect': // multiSelect
                        question.id = tempQuestion.id;
                        question.type = 4;
                        question.title = tempQuestion.title;
                        question.titleFr = tempQuestion.titleFr;
                        question.titleNl = tempQuestion.titleNl;
                        question.helptext = tempQuestion.helpText;
                        question.helptextFr = tempQuestion.helpTextFr;
                        question.helptextNl = tempQuestion.helpTextNl;
                        question.values = tempQuestion.values;
                        category.questions.push(question);
                        break;
                    case 'siSelect': // singleSelect
                        question.id = tempQuestion.id;
                        question.type = 2;
                        question.title = tempQuestion.title;
                        question.titleFr = tempQuestion.titleFr;
                        question.titleNl = tempQuestion.titleNl;
                        question.helptext = tempQuestion.helpText;
                        question.helptextFr = tempQuestion.helpTextFr;
                        question.helptextNl = tempQuestion.helpTextNl;
                        question.values = tempQuestion.values;
                        category.questions.push(question);
                        break;
                    case 'proSelect': // progressSelect
                        question.id = tempQuestion.id;
                        question.type = 1;
                        question.title = tempQuestion.title;
                        question.titleFr = tempQuestion.titleFr;
                        question.titleNl = tempQuestion.titleNl;
                        question.helptext = tempQuestion.helpText;
                        question.helptextFr = tempQuestion.helpTextFr;
                        question.helptextNl = tempQuestion.helpTextNl;
                        question.category = tempQuestion.category;
                        question.userInput = tempQuestion.userInput;
                        question.values = tempQuestion.values;

                        const minFr = tempQuestion.values[0].descriptionFr;
                        const maxFr = tempQuestion.values[(tempQuestion.values.length - 1)].descriptionFr;
                        const minNl = tempQuestion.values[0].descriptionNl;
                        const maxNl = tempQuestion.values[(tempQuestion.values.length - 1)].descriptionNl;
                        question.minFr = minFr;
                        question.maxFr = maxFr;
                        question.minNl = minNl;
                        question.maxNl = maxNl;

                        category.questions.push(question);
                        break;
                    case 'text':
                        question.id = tempQuestion.id;
                        question.type = 3;
                        question.title = tempQuestion.title;
                        question.titleFr = tempQuestion.titleFr;
                        question.titleNl = tempQuestion.titleNl;
                        question.onlyNumbers = false;
                        question.helptext = tempQuestion.helpText;
                        question.helptextFr = tempQuestion.helpTextFr;
                        question.helptextNl = tempQuestion.helpTextNl;
                        category.questions.push(question);
                        break;
                    case 'building':
                        question.id = tempQuestion.id;
                        question.type = 5;
                        question.title = tempQuestion.title;
                        question.titleFr = tempQuestion.titleFr;
                        question.titleNl = tempQuestion.titleNl;
                        question.helptext = tempQuestion.helpText;
                        question.helptextFr = tempQuestion.helpTextFr;
                        question.helptextNl = tempQuestion.helpTextNl;
                        question.values = tempQuestion.values;
                        category.questions.push(question);
                        break;
                }

                if ((i + 1) === e.length) {
                    sampleQuestions.push(category);
                }
            }
            this.questions = sampleQuestions;
            return Q;
        }).catch((err) => this.appService.handleError(err));

    }

    public getFirstQuestion(idQ = 2): Observable<Object> { // MBR2703-1101 // idQ : MBR2004-1232
        const simulationID = this.appService.simulationNumber;
        const questionPost = {
            id: idQ, // MBR2004-1232
            title: '',
            titleFr: '',
            titleNl: '',
            helpText: '',
            helpTextFr: '',
            helpTextNl: '',
            type: '',
            category: '',
            userInput: '',
            values: []
        };

        return this.http.post(environment.apiUrl + '/api/simulations/' + simulationID + '/next-question',
            JSON.stringify(questionPost),
            this.authService.getHttpOptions(true)).map((fQ: any) => {
                let e = fQ;

                if (!e || e === 'null') { // MBR0904-1201
                    e = [];
                    e.index = 1; // MBR0705-1251
                    return e; // MBR0705-1251
                } else {
                    if (idQ > 1) { // MBR2004-1232
                        const currentCatIndex = this.getCurrentCategoryIndex() + 1;

                        const data = this.questions[currentCatIndex - 1].questions;
                        const index = data.map(function (d) { return d['id']; }).indexOf(e.id);
                        e.index = index + 1; // MBR0705-1251

                        this.nextQuestion = e; // MBR0705-1251
                        this.nextQuestionInCategoryId = e['id']; // MBR0705-1251

                        return e; // MBR0705-1251
                    } else { // MBR2004-1232
                        return e;
                    }
                }
        }).catch((err) => this.appService.handleError(err));
    }

    public getSimulationInformations(): Observable<Object> { // MBR2610-1352
        return this.http.get(environment.apiUrl + '/api/simulations/' + this.appService.simulationNumber, this.authService.getHttpOptions())
        .map((companyData: any) => {
            const e = companyData;
            this.appService.survey = e.survey;
            this.appService.version = e.version;
            return e;
        }).catch((err) => this.appService.handleError(err));
    }

    public getNextPage(force = false): string {
        let url = '';

        const currentCat = this.getCurrentCategory();
        const currentCatIndex = this.getCurrentCategoryIndex() + 1;
        const totalQuestions = currentCat.questions.length;
        const totalCats = this.questions.length;

        const data = this.questions[currentCatIndex - 1].questions;
        const index = data.map(function(d) { return d['id']; }).indexOf(this.nextQuestionInCategoryId);

        if (index !== -1) {
            url = './wizard/question/' + currentCatIndex + '/' + (index + 1);
        } else if (currentCatIndex + 1 <= totalCats) {

            if (force) {
                url = './wizard/question/' + (currentCatIndex + 1) + '/' + 1;

            } else {
                url = './wizard/transition/' + currentCatIndex + '/' + (currentCatIndex + 1);
            }

        } else {
            url = './wizard/result';

        }
        return url;
    }
    public setGoingBack(goingBack: boolean): void {
        this.goingBack = goingBack;
    }
    public getGoingBack(): boolean {
        return this.goingBack;
    }
    public getPrevPage(): string {
        let url = '';

        const currentCat = this.getCurrentCategory();
        const currentCatIndex = this.getCurrentCategoryIndex() + 1;
        const totalQuestions = currentCat.questions.length;

        let data = this.questions[currentCatIndex - 1].questions;
        let index = data.map(function(d) { return d['id']; }).indexOf(this.prevQuestionInCategoryId);

        if (index > -1) {
            url = './wizard/question/' + currentCatIndex + '/' + (index + 1);
        } else if (currentCatIndex - 1 > 0) {
            data = this.questions[currentCatIndex - 2].questions;
            index = data.map(function(d) { return d['id']; }).indexOf(this.prevQuestionInCategoryId);
            url = './wizard/question/' + (currentCatIndex - 1) + '/' + (index + 1);
        } else {
            url = './wizard/start';
        }
        return url;
    }
    public getCurrentCategoryQuestions(): any {
        if (this.questions.length === 0) {
            return {};
        }

        return this.questions[this.currentCategory].questions;
    }
    public getCurrentCategory(): any {
        if (this.questions.length === 0) {
            return {};
        }
        return this.questions[this.currentCategory];
    }
    public shouldFadeOutProgress(force = false): any {
        const currentIndex = this.getCurrentQuestionIndex() + 1;
        const currentCat = this.getCurrentCategory();
        const currentCatIndex = this.getCurrentCategoryIndex() + 1;
        const totalQuestions = currentCat.questions.length;
        const totalCats = this.questions.length;
        if (currentIndex + 1 <= totalQuestions) {
            return false;
        } else if (currentCatIndex + 1 <= totalCats) {
            if (force) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    public shouldIncreaseHeader(): any {
        const currentIndex = this.getCurrentQuestionIndex() + 1;
        const currentCat = this.getCurrentCategory();
        const currentCatIndex = this.getCurrentCategoryIndex() + 1;
        const totalQuestions = currentCat.questions.length;
        const totalCats = this.questions.length;
        if (currentIndex + 1 <= totalQuestions) {
            return false;
        } else if (currentCatIndex + 1 <= totalCats) {

            return false;
        } else {
            return true;
        }
    }

    public setFadeInProgress(): any {
        this.fadeInProgress = true;
    }
    public getFadeInProgress(): any {
        return this.fadeInProgress;
    }
    public disableFadeInProgress(): any {
        this.fadeInProgress = false;
    }
    public getCurrentCategoryIndex(): any {
        return this.currentCategory;
    }
    public getCurrentQuestionIndex(): any {
        return this.currentQuestionInCategory;
    }
    public getCurrentQuestion(): any {
        if (this.questions.length === 0) {

            return {};
        }
        return this.questions[this.currentCategory].questions[this.currentQuestionInCategory];
    }
    public getNextQuestion(): any {
        if (this.questions.length === 0) {
            return {};
        }
        return this.questions[this.currentCategory].questions[(this.currentQuestionInCategory + 1)];
    }
    public setProgress(categoryNumber: number, questionNumber: number): any {

        this.currentCategory = categoryNumber - 1;
        this.currentQuestionInCategory = questionNumber - 1;
    }
    public storeResponse(question: Question): Observable<Object> {
        const questionPost: any = {
            id : question.id,
            title : question.title,
            helpText : question.helptext,
            type : '',
            category : question.category,
            userInput : '',
            values : question.values
        };
        const simulationID = this.appService.simulationNumber;

        switch (question.type) {
            case 1 : // progess select
                questionPost.type = 'proSelect';
                questionPost.userInput = question.userInput;

                for (const i in questionPost.values) {
                    if (questionPost.values.hasOwnProperty(i)) {
                        questionPost.values[i].selected = false;
                    }
                }
                question.selectedValue[0].selected = true; // on met le paramètre selected de la réponse à vrai
                questionPost.values[question.selectedValue.id] = question.selectedValue;
                // on injecte la réponse mise à jour dans le json de la question
                break;
            case 2 : // single select
                questionPost.type = 'siSelect';
                // console.log("QuestionPOST : " + questionPost.values[1]);
                for (const i in questionPost.values) {
                    if (questionPost.values.hasOwnProperty(i)) {
                        questionPost.values[i].selected = false;
                    }
                }
                questionPost.values[question.selectedValue.id - 1].selected = !question.selectedValue.selected;
                break;
            case 3 : // integer
                questionPost.type = 'integer';
                questionPost.userInput = question.selectedValue;
                break;
            case 4 : // multiSelect
                questionPost.type = 'muSelect';
                for (const i in questionPost.values) {
                    if (questionPost.values.hasOwnProperty(i)) {
                        questionPost.values[i].selected = false;
                    }
                }
                for (const RespVal of question.selectedValue) {
                    const data = questionPost.values;
                    const index = data.map(function(d) { return d['text']; }).indexOf(RespVal);
                    questionPost.values[index].selected = true;
                }
                break;
            case 5 : // building
                questionPost.type = 'building';
                questionPost.values = question.selectedValue;
                break;
        }

        return this.http.post(environment.apiUrl + '/api/simulations/' + simulationID + '/next-question',
            JSON.stringify(questionPost), this.authService.getHttpOptions(true)).catch((err) => this.appService.handleError(err));
    }
    public postBuildings(question: Question): Observable<Object> {
        return this.http.post(environment.apiUrl + '/api/simulations/' + this.appService.simulationNumber + '/buildings',
            question.selectedValue, this.authService.getHttpOptions(true)).catch((err) => this.appService.handleError(err));
    }

    public getResponse(question: Question): Observable<Object> {
        // console.log("QUESTIONtoPOST: " + JSON.stringify(question, null, "   "));
        const questionPost: any = {
            id : question.id,
            title : question.title,
            helpText : question.helptext,
            type : '',
            category : question.category,
            userInput : '',
            values : question.values
        };
        const simulationID = this.appService.simulationNumber;

        switch (question.type) {
            case 1 : // progess select
                questionPost.type = 'proSelect';
                break;
            case 2 : // single select
                questionPost.type = 'siSelect';
                break;
            case 3 : // integer
                questionPost.type = 'integer';
                break;
            case 4 : // multiSelect
                questionPost.type = 'muSelect';
                break;
            case 5 : // building
                questionPost.type = 'building';
                break;
        }

        // this.questions[this.currentCategory].questions[this.currentQuestionInCategory] = question;

        return this.http.post(environment.apiUrl + '/api/simulations/' + simulationID + '/previous-question',
            JSON.stringify(questionPost),
            this.authService.getHttpOptions(true)).catch((err) => this.appService.handleError(err));
    }

}
