import { Component, OnInit, ViewChild, ElementRef, Renderer2, HostListener, AfterViewInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { CustomTranslateService } from '../../../shared/services/index';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'better-proposal-modal',
  styleUrls: ['./better-proposal-modal.component.scss'],
  templateUrl: './better-proposal-modal.component.html'
})
export class BetterProposalModalComponent {

  public title: string = this.appService.popupTitle;
  public text: string = this.appService.popupText;
  constructor(
    private appService: AppService,
    private renderer: Renderer2,
    private translateService: CustomTranslateService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

}
