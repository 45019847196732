import { Component, OnInit, Renderer2, ViewChild, ElementRef, HostListener } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppService } from '../../../app.service';
import { WizardService } from '../../../shared/services/wizard.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-wizard-question',
    styleUrls: ['./wizard-question.component.scss'],
    templateUrl: './wizard-question.component.html'
})
export class WizardQuestionComponent implements OnInit {

    public questionType5AddressFieldsDisplayed = false; // MBR2812-136
    private questionNumber = 0;
    private categoryNumber = 0;
    public currentQuestion: any = null;
    public nextQuestion: any = null;

    @ViewChild('currentQuestionHtml', {static: false})
    private currentQuestionEl: ElementRef;
    @ViewChild('nextQuestionHtml', {static: false})
    private nextQuestionEl: ElementRef;
    @ViewChild('progress', {static: false})
    private progress: ElementRef;
    @ViewChild('pageHeader', {static: false})
    private pageHeader: ElementRef;
    public hasError = true;
    public hasError2 = false;
    public hasErrorText = '';
    public hover = false; // MBR1804-1221
    constructor(
        public appService: AppService, // MBR0901-1401
        private renderer: Renderer2,
        public wizardService: WizardService,
        private router: Router,
        private route: ActivatedRoute,
    ) {

    }
    public ngOnInit(): void {
        if (this.wizardService.getAllQuestions().length === 0) {
            this.wizardService.getQuestions();
        }
        this.setQuestion();
        this.checkSession();
        history.replaceState('', '', '/' + this.wizardService.sessionId + '/question/' + this.currentQuestion.id);
    }
    public unloadForNextQuestion(): void {
        if (!this.validAnswer()) {
            this.hasError = true;
            return;
        } else {
            this.hasError = false;
        }

        const self = this;

        self.currentQuestionEl.nativeElement.classList.add('fadeOutUp');
        if (self.nextQuestionEl && self.nextQuestionEl.nativeElement) {
            self.nextQuestionEl.nativeElement.classList.remove('delay1300');
            self.nextQuestionEl.nativeElement.classList.add('fadeOutUp');
        }
        if (self.wizardService.shouldFadeOutProgress()) {
            self.progress.nativeElement.classList.add('fadeOutUp');
        }
        if (self.wizardService.shouldIncreaseHeader()) {
            self.pageHeader.nativeElement.classList.add('forResults');
        }

        let i = 0; // MBR0405-1251 : uniquement si erreur, l'appel à l'api se fait autant de fois que l'utilisateur a cliqué sur le bouton
        setTimeout(function () {
            self.renderer.listen(self.currentQuestionEl.nativeElement, 'transitionend', () => {
                i += 1; // MBR0405-1251
                if (i === 1) { // MBR0405-1251
                    self.wizardService.setGoingBack(false);
                    self.storeAndNext(self);
                }
            });
            self.renderer.listen(self.currentQuestionEl.nativeElement, 'webkitTransitionEnd', () => {
                i += 1; // MBR0405-1251
                if (i === 1) { // MBR0405-1251
                    self.wizardService.setGoingBack(false);
                    self.storeAndNext(self);
                }
            });
            self.renderer.listen(self.currentQuestionEl.nativeElement, 'animationend', () => {
                i += 1; // MBR0405-1251
                if (i === 1) { // MBR0405-1251
                    self.wizardService.setGoingBack(false);
                    self.storeAndNext(self);
                }
            });
        }, 100);
    }

    private storeAndNext(self): void {
        if (this.currentQuestion.type === 5) {
            this.storeBuildings(). subscribe(res => {
                self.storeResponse().subscribe((nextQuestion) => {
                    if (this.appService.trackQuestionId !== 0) {
                        this.wizardService.nextQuestion = nextQuestion;
                    } else {
                        this.getQuestion();
                    }
                    if (nextQuestion !== null) {
                        self.wizardService.nextQuestionInCategoryId = nextQuestion.id;
                    } else {
                        self.wizardService.nextQuestionInCategoryId = -1;
                    }
                    self.router.navigate([self.wizardService.getNextPage()]);
                },
                (error) => {
                    this.hasError2 = true;
                    this.pageHeader.nativeElement.classList.remove('forResults');
                    this.currentQuestionEl.nativeElement.classList.remove('fadeOutUp');
                    this.progress.nativeElement.classList.remove('fadeOutUp');
                });
            },
            (error) => {
                this.hasError2 = true;
                this.pageHeader.nativeElement.classList.remove('forResults');
                this.currentQuestionEl.nativeElement.classList.remove('fadeOutUp');
                this.progress.nativeElement.classList.remove('fadeOutUp');
            });
        } else {
            self.storeResponse().subscribe((nextQuestion) => {
                // console.log('! Question qui doit être affichée ! : ' + JSON.stringify(nextQuestion, null, "    "));
                if (this.appService.trackQuestionId !== 0) { // && nextQuestion.id <= this.appService.trackQuestionId) {
                    this.wizardService.nextQuestion = nextQuestion;
                } else {
                    this.getQuestion();
                }
                if (nextQuestion !== null) {
                    self.wizardService.nextQuestionInCategoryId = nextQuestion.id;
                } else {
                    self.wizardService.nextQuestionInCategoryId = -1;
                }
                self.router.navigate([self.wizardService.getNextPage()]);
            },
            (error) => {
                // let err = JSON.parse(error); // MBR0608-135
                this.hasError2 = true;
                // MBR0901-1401 : this.hasErrorText = error['Message']; // MBR0608-135
                this.currentQuestionEl.nativeElement.classList.remove('fadeOutUp');
                this.progress.nativeElement.classList.remove('fadeOutUp');
                // return;
            });
        }
    }

    private checkSession(): void {
        if (!this.wizardService.hasCurrentSession()) {
            // Should redirect to start? In order to get address info
            this.router.navigate(['/risk-calculator']);
        }
    }
    private getQuestion(): void {
        this.hasError = false;
        this.appService.catchError = false; // MBR1103-1421
        this.currentQuestion = this.wizardService.getCurrentQuestion();
        // console.log("get question : " + JSON.stringify(this.currentQuestion, null, "   "));
    }
    private validAnswer(): boolean {
        return (this.currentQuestion.selectedValue !== null &&
                this.currentQuestion.selectedValue !== undefined &&
                this.currentQuestion.selectedValue !== '' &&
                this.currentQuestion.selectedValue.length !== 0); // MBR0405-1253 : ajout du check sur length
    }
    private storeResponse(): Observable<Object> {
        return this.wizardService.storeResponse(this.currentQuestion);
    }
    private storeBuildings(): Observable<Object> {
        return this.wizardService.postBuildings(this.currentQuestion);
    }
    public unloadForPrevQuestion(): void {
        this.wizardService.setGoingBack(true);
        this.currentQuestionEl.nativeElement.classList.add('fadeOutDown');
        if (this.nextQuestionEl) {
            this.nextQuestionEl.nativeElement.classList.remove('delay1300');
            this.nextQuestionEl.nativeElement.classList.add('fadeOutDown');
        }
        const self = this;
        let element = (<HTMLElement> document.querySelector('.progress-block.active.latest'));
        if (element) {
            element.classList.remove('latest');
            element.classList.remove('active');
        } else {
            const totalblocks = document.querySelectorAll('li.active .front .progress-block.active').length;
            element = (<HTMLElement> document.querySelectorAll('li.active .front .progress-block.active')[(totalblocks - 1)]);
            element.classList.remove('active');
        }
        // self.getResponse();

        this.wizardService.nextQuestion = null;

        setTimeout(function () {
            self.renderer.listen(self.currentQuestionEl.nativeElement, 'transitionend', () => {
                self.getResponseAndPrevious(self);
            });
            self.renderer.listen(self.currentQuestionEl.nativeElement, 'webkitTransitionEnd', () => {
                self.getResponseAndPrevious(self);
            });
            self.renderer.listen(self.currentQuestionEl.nativeElement, 'animationend', () => {
                self.getResponseAndPrevious(self);
            });
        }, 200);
    }
    private getResponseAndPrevious(self): void {
        self.getResponse().subscribe((prevQuestion) => {
            // console.log('! Question prec qui doit être affichée ! : ' + JSON.stringify(prevQuestion, null, "    "));
            self.wizardService.prevQuestionInCategoryId = prevQuestion.id;
            self.router.navigate([self.wizardService.getPrevPage()]);
        });
    }
    private getResponse(): Observable<Object> {
        return this.wizardService.getResponse(this.currentQuestion);
    }
    public getNextQuestion(): void {
        this.wizardService.nextQuestion = this.wizardService.getNextQuestion();
        // console.log("get next question : " + JSON.stringify(this.wizardService.nextQuestion, null, "   "));
    }
    public canPrevious(): boolean {
        /*
        if (this.currentQuestion === null || this.currentQuestion === undefined) {
          return false;
        }
        let currentIndex = this.wizardService.getCurrentQuestionIndex();
        return (currentIndex > 0);
        */
        return true;
    }
    public canNext(): boolean {
        if (this.currentQuestion === null || this.currentQuestion === undefined) {
            return false;
        }
        if (this.currentQuestion.selectedValue !== null && this.currentQuestion.selectedValue !== '') {
            return true;
        }
    }
    public setQuestion(): void {
        /*
        if (this.appService.trackQuestionId > 0) {
            let data = this.wizardService.getAllQuestions();
            let index = data.map(function(d) { return d['id']; }).indexOf(this.appService.trackQuestionId);
        } else {
          */
        this.route.params
            .subscribe((params) => {
                const newQuestion = this.questionNumber !== 0 && this.questionNumber !== params.question;
                this.questionNumber = params.question;
                this.categoryNumber = params.category;
                this.wizardService.setProgress(this.categoryNumber, this.questionNumber);
                // console.log('next question : ' + JSON.stringify(this.wizardService.nextQuestion, null, "   "));
                if (this.wizardService.nextQuestion !== null) {
                    if (this.appService.trackQuestionId !== 0 && this.wizardService.nextQuestion.id <= this.appService.trackQuestionId) {
                        // console.log('next question id : ' + this.wizardService.nextQuestion.id);
                        // this.wizardService.getQuestionAnswer(nextQuestion.id);
                        this.wizardService.setQuestionAnswer(this.wizardService.nextQuestion);
                    }
                }
                this.getQuestion();
                // this.getNextQuestion();
                if (newQuestion) {
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                        this.router.navigate(['/wizard/question/' + this.categoryNumber + '/' + this.questionNumber]));
                }
            });
        // }
    }
    public getLang(): string {
        const lang = this.appService.getLanguage();
        return lang.charAt(0).toUpperCase() + lang.slice(1).toLowerCase();
    }
    @HostListener('document:keydown', ['$event'])
    private onKeyDown(event): void {
        if ((event.key === 'Enter' || ((event.key === 'ArrowRight' || event.key === 'Right') && this.currentQuestion.type !== 3)) &&
            !this.questionType5AddressFieldsDisplayed && this.canNext()) { // MBR0210-1351 : type 5 // MBR2812-136
                this.unloadForNextQuestion();
        }
        if ((event.key === 'ArrowLeft' || event.key === 'Left') &&
            this.currentQuestion.type !== 3 &&
            !this.questionType5AddressFieldsDisplayed &&
            this.canPrevious()) { // MBR0210-1351 : type 5 // MBR2812-136
                this.unloadForPrevQuestion();
        }
    }
}
