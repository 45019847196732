import { Component, OnInit, Renderer2, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AppService } from '../../../app.service';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/delay';
import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/zip';
import { Router, ActivatedRoute } from '@angular/router';
import { WizardService } from '../../../shared/services/wizard.service';

@Component({
  selector: 'app-start',
  styleUrls: ['./start.component.scss'],
  templateUrl: './start.component.html'
})
export class StartComponent implements OnInit {
  @ViewChild('pageHeader', {static: false})
  private pageHeader: ElementRef;
  @ViewChild('loaderHeader', {static: false})
  private loaderHeader: ElementRef;
  @ViewChild('otherContent', {static: false})
  private otherContent: ElementRef;
  @ViewChild('progressCategories', {static: false})
  private progressCategories: ElementRef;
  @ViewChild('pageContent', {static: false})
  private pageContent: ElementRef;
  @ViewChild('title1', {static: false})
  private title1: ElementRef;
  @ViewChild('title2', {static: false})
  private title2: ElementRef;
  @ViewChild('title3', {static: false})
  private title3: ElementRef;
  @ViewChild('title4', {static: false})
  private title4: ElementRef;

  @ViewChild('block1', {static: false})
  private block1: ElementRef;
  @ViewChild('block2', { static: false})
  private block2: ElementRef;
  @ViewChild('block3', {static: false})
  private block3: ElementRef;
  @ViewChild('block4', {static: false})
  private block4: ElementRef;

  private sessionId: string;
  private firstQuestionId = '1'; // MBR2703-1101

  private loadingPromise = new Promise((resolve, reject)  => { // MBR2703-1101
    return '';
  });

  constructor(
    private appService: AppService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    public wizardService: WizardService,
    private router: Router
  ) { }
  public ngOnInit(): void {
    this.checkSession();
  }
  private checkSession(): void {
    this.route.params
      .subscribe((params) => {
        const uuid = params.uuid;
        if (uuid === null || uuid === undefined) {
          this.startSession();
        } else {
          this.verifySession();
        }
      });
  }
  /*
  private loadQuestions(): void {

    this.wizardService.getQuestions();
  }
  */
  private startSession(): void {
    const uuid = this.wizardService.getNewSessionId();
    this.router.navigate(['/wizard/start', uuid]);
    // this.verifySession();
  }
  private verifySession(): void {
    // Need to verify uuid
    this.wizardService.getQuestions().subscribe((Q) => {
      this.wizardService.getSimulationInformations().subscribe((e) => { // MBR2610-1352
        this.wizardService.getFirstQuestion().subscribe((fQ) => { // MBR2703-1101
          this.firstQuestionId = fQ['index'];
          /* MBR0705-1251
          if (this.appService.action === 'RESUMEPMECALC' && this.appService.trackQuestionId !== 0) {
            this.wizardService.getQuestionAnswer(this.firstQuestionId); // MBR2703-1101 // this.appService.trackQuestionId);
          }
          */
          this.loadingPromise = Promise.resolve('done');
        });
      });
    });
  }

  private animateBlocks(): void {
    const self = this;

    if (this.appService.isDas) {
      setTimeout(function () {
        self.renderer.listen(self.block4.nativeElement, 'transitionend', () => {
          self.router.navigate(['/wizard/question/1/' + self.firstQuestionId]); // MBR2703-1101
        });
        self.renderer.listen(self.block4.nativeElement, 'webkitTransitionEnd', () => {
          self.router.navigate(['/wizard/question/1/' + self.firstQuestionId]); // MBR2703-1101
        });
        self.renderer.listen(self.block4.nativeElement, 'animationend', () => {
          self.router.navigate(['/wizard/question/1/' + self.firstQuestionId]); // MBR2703-1101
        });

      }, 1200);
      const fakeStream = [this.block1, this.block2, this.block3, this.block4];
      Observable.from(fakeStream)
        .zip(Observable.timer(0, 150), (x) => x)
        .subscribe((element) => {
          element.nativeElement.classList.add('animate1');
        });
    } else {
      setTimeout(function () {
        self.renderer.listen(self.block2.nativeElement, 'transitionend', () => {
          self.router.navigate(['/wizard/question/1/' + self.firstQuestionId]); // MBR2703-1101
        });
        self.renderer.listen(self.block2.nativeElement, 'webkitTransitionEnd', () => {
          self.router.navigate(['/wizard/question/1/' + self.firstQuestionId]); // MBR2703-1101
        });
        self.renderer.listen(self.block2.nativeElement, 'animationend', () => {
          self.router.navigate(['/wizard/question/1/' + self.firstQuestionId]); // MBR2703-1101
        });

      }, 600);
      const fakeStream = [this.block1, this.block2];
      Observable.from(fakeStream)
        .zip(Observable.timer(0, 150), (x) => x)
        .subscribe((element) => {
          element.nativeElement.classList.add('animate1');
        });
    }
  }
  public unloadPage(): void {
    this.loadingPromise.then((val) => { // MBR2703-1101
      const self = this;
      this.renderer.listen(this.loaderHeader.nativeElement, 'transitionend', () => {
        self.animateBlocks();
      });
      this.renderer.listen(this.loaderHeader.nativeElement, 'webkitTransitionEnd', () => {
        self.animateBlocks();
      });
      this.renderer.listen(this.loaderHeader.nativeElement, 'animationend', () => {
        self.animateBlocks();
      });
      setTimeout(function () { self.animateBlocks(); }, 200);
      this.loaderHeader.nativeElement.classList.add('fadeOutUp');
      this.pageHeader.nativeElement.classList.add('wizard_active');
      this.pageContent.nativeElement.classList.add('wizard_active');
      this.otherContent.nativeElement.classList.add('fadeOut');
      this.title1.nativeElement.classList.add('fadeOutUp');
      this.title2.nativeElement.classList.add('fadeOutUp');
      if (this.appService.isDas) {
        this.title3.nativeElement.classList.add('fadeOutUp');
        this.title4.nativeElement.classList.add('fadeOutUp');
      }
    });
  }

  @HostListener('document:keydown', ['$event'])
  private onKeyDown(event): void {
    if (event.key === 'Enter' || (event.key === 'ArrowRight' || event.key === 'Right')) {
      this.unloadPage();
    }
  }
}
