import { Component, OnInit, Renderer2, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { AppService } from '../../../app.service';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/delay';
import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/zip';
import { Router, ActivatedRoute } from '@angular/router';
import { WizardService } from '../../../shared/services/wizard.service';

@Component({
  selector: 'transition',
  styleUrls: ['./transition.component.scss'],
  templateUrl: './transition.component.html'
})
export class TransitionComponent implements OnInit, AfterViewInit {
  public nextQuestion: any = null;
  
  @ViewChild("pageHeader",{static: false})
  private pageHeader: ElementRef;

  @ViewChild("loaderHeader",{static: false})
  private loaderHeader: ElementRef;
  @ViewChild("otherContent",{static: false})
  private otherContent: ElementRef;

  @ViewChild("pageContent",{static: false})
  private pageContent: ElementRef;

  public fromCategory: number = 1;
  public toCategory: number = 1;
  constructor(
    private appService: AppService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private wizardService: WizardService
  ) {
  }

  public ngOnInit(): void {
    this.setCategories();
    let self = this;
  }
  public ngAfterViewInit(): void {
    let self = this;
    setTimeout(function () {
      self.pageHeader.nativeElement.classList.add("makeSmaller");
    }, 500);
    setTimeout(function () {
      self.unloadPage();
    }, 3000);
  }
  private setCategories(): void {
    this.route.params
      .subscribe((params) => {
        this.fromCategory = parseInt(params.from, 10);
        this.toCategory = parseInt(params.to, 10);
      });
  }
  private unloadPage(): void {
    let self = this;
    self.loaderHeader.nativeElement.classList.remove("delay1000");
    self.loaderHeader.nativeElement.classList.add("fadeOutUp");
    self.otherContent.nativeElement.classList.add("fadeOutUp");
    self.wizardService.setFadeInProgress();
    setTimeout(function () {
      self.pageHeader.nativeElement.classList.remove("makeSmaller");
    }, 500);

    setTimeout(function () {
      self.renderer.listen(self.pageHeader.nativeElement, 'transitionend', () => {
        self.wizardService.setGoingBack(false);
        self.router.navigate([self.wizardService.getNextPage(true)]);
      });
      self.renderer.listen(self.pageHeader.nativeElement, 'webkitTransitionEnd', () => {
        self.wizardService.setGoingBack(false);
        self.router.navigate([self.wizardService.getNextPage(true)]);
      });
      self.renderer.listen(self.pageHeader.nativeElement, 'animationend', () => {
        self.wizardService.setGoingBack(false);
        self.router.navigate([self.wizardService.getNextPage(true)]);
      });
    }, 600);
  }
  /* MBR0905-1261
  @HostListener('document:keydown', ['$event'])
  private onKeyDown(event): void {
    if (event.key === "Enter" || (event.key === "ArrowRight" || event.key === "Right")) {
      this.unloadPage();
    }
  }
  */
}
