import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertService } from './shared/services/index';
import { AlertTypes } from './shared/models/index';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';

export interface InternalStateType {
    [key: string]: any
}

@Injectable()
export class AppService {

    public _state: InternalStateType = {};
    public vatNumber = '';
    public simulationNumber = '';
    public companyName = '';
    public action = '';
    public actionValue = '';
    public brokerName = '';
    public brokerEmail = '';
    public trackQuestionId = 0;
    public survey = 0; // MBR2610-1352 2 = Entreprise 3 = ASBL 4 = médical
    public version = 0; // MBR2610-1352
    public popupTitle = '';
    public popupText = '';
    // public errorText: string = ""; // MBR0901-1401
    public catchError = false;
    public ExceptionMessage: any; // MBR0901-1401
    public errorText: any = { // MBR0901-1401
        TexteFr: '',
        TexteNl: '',
        Id: 0
    };
    public errorList: any = []; // MBR0901-1401
    public id: any; // MBR0901-1401
    public language = 'nl';
    public hasError = false; // MBR0901-1401
    public hasErrorText: any; // MBR0901-1401
    public isIEOrEdge: boolean; // MBR0901-1401
    public isFirefox: boolean; // MBR0901-1401
    public isChrome: boolean; // MBR0901-1401
    public isDas: boolean;
    public isBelfius: boolean;
    public isDvv: boolean;
    public verifyTokenOnRedirect = false;

    constructor(
        private alertService: AlertService,
        private translationService: TranslateService,
        private router: Router,
        private http?: HttpClient // MBR0901-1401
    ) {
        this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent); // MBR0901-1401
        this.isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1; // MBR0901-1401
        this.isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1; // MBR0901-1401
        this.isBelfius = environment.isBelfius;
        this.isDvv = environment.isDvv;
        this.isDas = environment.isDas;
     }
    // already return a clone of the current state
    public get state() {
        return this._state = this._clone(this._state);
    }
    // never allow mutation
    public set state(value) {
        throw new Error('do not mutate the `.state` directly');
    }

    public get(prop?: any) {
        // use our state getter for the clone
        const state = this.state;
        return state.hasOwnProperty(prop) ? state[prop] : state;
    }

    public set(prop: string, value: any) {
        // internally mutate our state
        return this._state[prop] = value;
    }

    /* MBR0901-1401
    public handleError(error: Response | any) {
        this.catchError = true;
        // console.log(error);
        try {
            this.errorText = JSON.parse(error.text());
        } catch (e) {
            try {
                this.errorText = error.text();
            } catch (ee) {
                this.errorText = error;
            }
        }
        // this.alertService.sendAlert("Error occured:" + error.text(), AlertTypes.DANGER, true);
        // console.log("Error occured:" + error.text());
        return Observable.throw(this.errorText);

    }
    */

    // MBR0901-1401 : fonction pour récupérer la liste des erreurs + méthode pour extraire et filtrer l'id du JSON
    public displayErrors(id: any): Observable<Response> {
        return this.http.get('err/errors').map((list) => {
            const f = list;
            this.errorList = f;
            return this.errorList.filter((e) => { return String(e.Id) === String(id); });
        }).catch((err) => this.handleError(err));
    }

    public handleError(error: Response | any) { // MBR0901-1401
        console.error('Caught error: ', error);
        this.catchError = true;

        try {
            // pour Chrome : parfois Code + Message / parfois ExceptionMessage + Message
            // pour Firefox : Code + Message
            // this.errorText = JSON.parse(error.text());
            this.errorText = error.error;
        } catch (e) {
            try {
                // pour IE : Code + Message
                // pour EDGE : ExceptionMessage + Message
                this.errorText = error.text();

            } catch (ee) {
                this.errorText = error;
            }
        }

        // l'id à exploiter sur le backend MessageAPI se trouve dans ExceptionMessage
        // donc, s'il n'y a rien dans ExceptionMessage, pas besoin d'appeler l'API
        if (this.errorText['ExceptionMessage'] !== undefined) {
            // il se peut que parfois ExceptionMessage contienne du texte plutot qu'un id
            // donc, pas besoin d'appeler l'API
            const numbers = new RegExp(/^[0-9]+$/);
            if (numbers.test(this.errorText['ExceptionMessage'])) {
                // si l'API a été appelé au moins une fois, la variable errorList sera déjà alimentée
                // donc, pas besoin d'appeler l'API
                if (this.errorList.length > 0) {
                    this.errorText = this.errorList.filter((e) => {
                        return String(e.Id) === String(this.errorText['ExceptionMessage']);
                    })[0];
                } else {
                    this.displayErrors(this.errorText['ExceptionMessage']).subscribe((err) => {
                        if (err[0] !== undefined && err[0] !== null && err[0] !== '') {
                            this.errorText = err[0];
                            this.hasErrorText = this.errorText;
                            this.catchError = true;
                        } else {
                            this.catchError = true;
                        }
                    });
                }
            }
        } // pas besoin de "else" car on garde le message d'erreur original (alimenté dans le try catch)
        return Observable.throw(this.errorText);
    }

    public returnErrorText(): string { // MBR0901-1401

        // priorité aux messages retournés par le backend (car contiennent les traductions)
        // donc, si errorText contient Id c'est que ça vient du backend

        if (this.errorText['Id'] !== undefined) {
            if (this.errorText['Id'] === 0) { // MBR1401-1401
                return this.errorText['Message'];
            } else {
                if (this.errorText['Texte' + this.getLang()] !== undefined) {
                    return this.errorText['Texte' + this.getLang()];
                } else {
                    return this.errorText['Message'];
                }
            }
        } else {
            // si errorText contient ExceptionMessage, ça veut dire que celui-ci contient du texte (car test dans handle)
            if (this.errorText['ExceptionMessage'] !== undefined) {
                return this.errorText['ExceptionMessage'];
            } else {
                // sinon si errorText contient Message on utilise celui-ci
                // (pas de traduction, le message renvoyé est celui de la langue utilisée à ce moment là)
                if (this.errorText['Message'] !== undefined) {
                    return this.errorText['Message'];
                } else {
                    // sinon, c'est qu'on a pas réussi à extraire un message d'erreur donc on renvoie l'erreur brut
                    return 'General error occured';
                }
            }
        }
    }

    public extractLanguage(): void {
        // TODO : appliquer la langue venant de l'api lors de l'appel du secure token (si pas de langue alors NL par défaut)
        this.setLanguage('nl');
    }
    public setLanguage(language: string): void {
        // TODO : stoker le simID pour faire un refresh de la page
        if (language === undefined || language === null || language === '') {
            this.language = 'nl';
        } else {
            this.language = language;
        }

        this.translationService.setDefaultLang(language);
        this.translationService.use(language);
    }

    public getLanguage(): string {
        return this.language;
    }

    public getLanguageAPI(): string {
        let lang;

        switch (this.getLanguage()) {
            case 'nl':
                lang = 'N';
                break;
            case 'fr':
                lang = 'F';
                break;
            default: lang = 'F';
        }
        return lang;
    }

    public getLang(): string { // MBR0901-1401
        const lang = this.getLanguage();
        return lang.charAt(0).toUpperCase() + lang.slice(1).toLowerCase();
    }

    private _clone(object: InternalStateType) {
        // simple object clone
        return JSON.parse(JSON.stringify(object));
    }
}
