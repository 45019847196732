import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  AfterViewInit
} from '@angular/core';

import { AppService } from '../../app.service';
import { Router } from '@angular/router';
import { AuthService } from '../../auth';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html'
})
export class HomeComponent implements AfterViewInit {
  public companyNumber = '';
  @ViewChild('checkList', { static: false})
  private checkList: ElementRef;
  @ViewChild('inputContainer', {static: false})
  private inputContainer: ElementRef;
  @ViewChild('disclaimer', { static: false})
  private disclaimer: ElementRef;
  public hover = false; // MBR1804-1221
  constructor(
    public appService: AppService, // MBR0901-1401
    private renderer: Renderer2,
    private authService: AuthService,
    private http: HttpClient,
    private router: Router,
  ) {
  }

  public ngAfterViewInit(): void {
    history.replaceState('', '', '/#/risk-calculator'); // MBR2803-1104
    /* MBR0901-1401
    setTimeout(() => {
      this.hasError = this.appService.catchError;
      this.hasErrorText = this.appService.errorText['Message'];
      this.appService.catchError = false;
      this.appService.errorText = "";
    }, 0);
    */
    this.renderer.listen(this.checkList.nativeElement, 'transitionend', () => {
      this.goToCompanyResults(this);
    });
    this.renderer.listen(this.checkList.nativeElement, 'webkitTransitionEnd', () => {
      this.goToCompanyResults(this);
    });
    this.renderer.listen(this.checkList.nativeElement, 'animationend', () => {
      this.goToCompanyResults(this);
    });

  }

  private animateLoading(): void {
    this.checkList.nativeElement.classList.add('fadeOutUp');
    this.inputContainer.nativeElement.classList.add('fadeOut');
    this.disclaimer.nativeElement.classList.add('fadeOut');

  }
  public gotoLegalProblem(): void {
    this.router.navigate(['../legal-problem']);
  }

  public getCompanyInformation(): void {
    this.appService.catchError = false; // MBR0901-1401
    this.appService.errorText = ''; // MBR0901-1401
    // this.retrieveCompanyDataTest();
    this.animateLoading();
  }

  private retrieveCompanyDataTest() {
    const params: any = {
      vatNumber: this.companyNumber
    };

    // console.log(params.toString());

    return this.http.post(environment.apiUrl + '/api/simulations', JSON.stringify(params), this.authService.getHttpOptions(true))
      .subscribe((companyData) => {
        return companyData;
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        if (err.error instanceof Error) {
          // we never seem to get here
          console.log('An error occurred:', err.error.message);
        } else {
          // no network connection, HTTP404, HTTP500, HTTP200 & invalid JSON
          console.log(`Backend returned code ${err.status}, body was: ${err.error}`);
        }
      });
  }


  private goToCompanyResults(self: any): void {
    this.appService.vatNumber = this.companyNumber;
    self.router.navigate(['../company-information']);
  }
}
