
export class Address {

    constructor(
        public street?: string,
        public strnumber?: string,
        public box?: string,
        public zip?: string,
        public city?: string,
        public country?: string,
        public notes?: string,
    ) { }
}
