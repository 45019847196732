import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormValidationService } from "./services/form-validation.service";
import { ConfirmDialogService } from "./services/confirm-dialog.service";
import { InputValidationComponent } from "./components/input-validation/input-validation.component";
import { MatSelectModule, MatCheckboxModule, MatDialogModule, MatRadioModule, MatDatepickerModule, MatInputModule, MatNativeDateModule, MatSlideToggleModule, MatAutocompleteModule } from '@angular/material';
import { LoaderComponent } from "./components/loader/index";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Validations } from "../validations";
import { TranslatePipe } from './pipes/translate';
import { NumberPipe } from './pipes/num.pipe';
import { IconPipe } from './pipes/icon';
import { FilterPipe } from './pipes/filter.pipe';

@NgModule({
    declarations: [
        IconPipe,
        FilterPipe,
        TranslatePipe,
        NumberPipe,
        LoaderComponent,
        InputValidationComponent],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatRadioModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatInputModule,
        MatNativeDateModule,
        MatDialogModule,
        MatSlideToggleModule,
        ReactiveFormsModule
    ],
    providers: [
        FormValidationService,
        ConfirmDialogService,
        Validations

    ],
    exports: [
        IconPipe,
        FilterPipe,
        TranslatePipe,
        NumberPipe,
        DatePipe,
        LoaderComponent,
        MatAutocompleteModule,
        MatSelectModule,
        MatRadioModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatInputModule,
        MatNativeDateModule,
        MatDialogModule,
        MatSlideToggleModule,
        InputValidationComponent]
})
export class SharedModule {
}
