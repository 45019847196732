import { Component, Input, forwardRef, SimpleChanges } from '@angular/core';
import { AppService } from '../../../../app.service';
import { Question } from '../../../models/question';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { OnChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { WizardQuestionComponent } from '../../../../public/wizard/question/index';

@Component({
  selector: 'app-question-type1',
  styleUrls: ['./question-type1.component.scss'],
  templateUrl: './question-type1.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionType1Component),
      multi: true
    },
  ]
})

export class QuestionType1Component implements ControlValueAccessor {

  private currentValue = -1;
  private currentValueIndex = -1;
  private currentAnswer: any[] = [];
  @Input()
  public _question: Question;

  constructor(
    private appService: AppService,
    private wizardQuestion: WizardQuestionComponent
  ) { }

  public writeValue(value: any): void {
    if (value) {
      this._question = value;
      this.setInitial();

    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  public registerOnTouched(fn: any): void {
    // Empty
  }
  public setDisabledState?(isDisabled: boolean): void {
    // Empty

  }
  public get question() {
    return this._question;
  }

  public set question(val) {
    this._question = val;
    this.propagateChange(val);
  }
  private propagateChange = (_: any) => {    // Empty
  }
  private setInitial(): void {
    if (this._question.selectedValue) {
      // console.log("Set Initial Value : " + this._question.selectedValue[0]);
      const initialValue = this._question.selectedValue[0];
      this.setCurrentValueIndex(this._question.values.indexOf(initialValue));
    }
  }
  private setCurrentValueIndex(index: number): void {
    this.currentValueIndex = index;
    const currentAnswerObject = this._question.values[index];
    this.currentAnswer = currentAnswerObject;
    this._question.selectedValue = [this.currentAnswer];

  }

  private unloadPage(): void {
    this.wizardQuestion.unloadForNextQuestion();
  }

  public getLang(): string {
    const lang = this.appService.getLanguage();
    return lang.charAt(0).toUpperCase() + lang.slice(1).toLowerCase();
  }
}
