import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ReturnStatement } from '@angular/compiler/src/output/output_ast';

@Pipe({ name: 'findIcon' })
export class IconPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
        this.sanitizer = sanitizer;
    }

    public transform(StringNumber, Category, Answer: string = "") {
        let returnValue;
        if (Category === 'activity') {
            switch (StringNumber) { 
                case '1':
                    returnValue = 'icon-agriculture';
                    break;
                case '2':
                    returnValue = 'icon-batiment';
                    break;
                case '3':
                    returnValue = 'icon-immobilier';
                    break;
                case '4':
                    returnValue = 'icon-klant'; // Commerce
                    break;
                case '5':
                    returnValue = 'icon-horeca';   
                    break;
                case '6':
                    returnValue = 'icon-finance';   
                    break;
                case '7':
                    returnValue = 'icon-medical';  
                    break;
                case '8':
                    returnValue = 'icon-garagiste';  
                    break;
                case '9':
                    returnValue = 'icon-camion'; // Transport
                    break;
                case '10':
                    returnValue = 'icon-communicatie'; 
                    break;
                case '11':
                    returnValue = 'icon-education'; 
                    break;
                case '12':
                    returnValue = 'icon-autre'; 
                    break;
                default: returnValue = '';
            }
        } else if (Category === 'question') {
            switch (StringNumber) { 
                case 1 :
                    if (Answer.toUpperCase() === 'JA' || Answer.toUpperCase() === 'OUI') {
                        returnValue = 'ja';
                    } else {
                        returnValue = '500minder';
                    }
                    break;
                case 2 :
                    if (Answer.toUpperCase() === 'NEE' || Answer.toUpperCase() === 'NON') {
                        returnValue = 'nee';
                    } else {
                        returnValue = '500-1000';
                    }
                    break;
                case 3 :
                    if (Answer.toUpperCase() === '1001 - 2000') {
                        returnValue = '1000-2000';
                    } else {
                        returnValue = 'jenesaispas';
                    }                    
                    break;
                case 4 :
                    returnValue = '2000-4000';
                    break;
                case 5 :
                    returnValue = '4000-6000';
                    break;
                case 6 :
                    returnValue = '6000-8000';
                    break;
                case 7 :
                    returnValue = '8000-10000';
                    break;
                case 8 :
                    returnValue = '10000meer';
                    break;
                case 50 :
                    returnValue = 'familie';
                    break;
                case 60 :
                    returnValue = 'vooruwbedrijf';
                    break;
                case 70 :
                    returnValue = 'construction';
                    break;
                case 100 :
                    returnValue = 'France';
                    break;
                case 110 :
                    returnValue = 'Nederland';
                    break;
                case 120 :
                    returnValue = 'Germany';
                    break;
                case 130 :
                    returnValue = 'other-eu';
                    break;
                case 140 :
                    returnValue = 'outside-eu';
                    break;
                case 180 :
                    returnValue = 'klant';
                    break;
                case 190 :
                    returnValue = 'leverancier';
                    break;
                case 200 :
                    returnValue = 'werknemer';
                    break;
                case 210 :
                    returnValue = 'overheid';
                    break;
                case 220 :
                    returnValue = 'anders';
                    break;
                case 350 :
                    returnValue = 'particulier';
                    break;
                case 360 :
                    returnValue = 'ondernemingen';
                    break;
                case 410 :
                    returnValue = 'leasing';
                    break;
                case 420 :
                    returnValue = 'autoperso';
                    break;
                case 430 :
                    returnValue = 'autononperso';
                    break;
                case 440 :
                    returnValue = 'anders';
                    break;
                case 450 :
                    returnValue = 'auto';
                    break;
                case 460 :
                    returnValue = 'camion';
                    break;
                case 470 :
                    returnValue = 'moto';
                    break;
                case 480 :
                    returnValue = 'anders';
                    break;
                default: returnValue = '';
            }
        }
        return returnValue;
    }
}
