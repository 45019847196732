import { Component, OnInit, ViewChild, ElementRef, Renderer2, HostListener } from '@angular/core';
import { AppService } from '../../app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { BetterProposalModalComponent } from './better-proposal-modal/index';
import { AuthService } from '../../auth/index';
import { Observable } from 'rxjs/Rx';
import { AlertService, CustomTranslateService } from '../../shared/services/index';
import { WizardService } from '../../shared/services/wizard.service'; // MBR2004-1232
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-company-information',
    styleUrls: ['./company-information.component.scss'],
    templateUrl: './company-information.component.html'
})
export class CompanyInformationComponent implements OnInit {
    public limit: any = []; // MBR0410-1351
    public forceHide = true; // MBR0511-1351
    private limitCount = 4; // MBR0410-1351
    private codesWithoutLimit: any = []; // MBR0410-1351
    private codesExpandButton: any[] = []; // MBR0410-1351
    public displayArrowTop = false; // MBR0510-1351

    @ViewChild('preloadHeader', {static: false})
    private preloadHeader: ElementRef;
    @ViewChild('loadedHeader', {static: false})
    private loadedHeader: ElementRef;
    @ViewChild('companyInformation', {static: false})
    private companyInformation: ElementRef;

    @ViewChild('companyActivity', {static: false})
    private companyActivity: ElementRef;
    @ViewChild('companyGeneral', {static: false})
    private companyGeneral: ElementRef;
    @ViewChild('companyContact', {static: false})
    private companyContact: ElementRef;
    @ViewChild('companyLocation', {static: false})
    private companyLocation: ElementRef;
    @ViewChild('continue', {static: false})
    private continue: ElementRef;
    public hasErrorKBO = false;
    public hasErrorUpt = false; // MBR2004-1232
    private errorTxtUpt: string; // MBR2004-1232
    public company: any = {};
    public activities: any = [];
    private mainActivity: any = [];
    private mainActivityOriginal: any = []; // MBR1411-1351
    private professionalactivities: any = [];
    private subcats: number[] = [];
    private dataLoaded = false;
    private dataCompanyInserted = false; // CSA0604-1201
    public onClickEvent = false;
    private activitiesLoaded: Observable<Response>;
    // private popupAlreadyShown: boolean = false; // MBR0507-1341
    private mainActivityUnchecked: any = []; // MBR0407-1342
    constructor(
        private appService: AppService,
        private renderer: Renderer2,
        private alertService: AlertService,
        private dialog: MatDialog,
        private http: HttpClient,
        private route: ActivatedRoute,
        private authService: AuthService,
        private translation: CustomTranslateService,
        private router: Router,
        private wizardService: WizardService // MBR2004-1232
    ) { }
    public ngOnInit(): void {

        this.retrieveCompanyData().subscribe((companyData) => {
            this.getMainActivity().subscribe((activitiesResult) => {
                this.activitiesLoaded = Observable.throw(activitiesResult); // MBR0407-1341
                this.retrieveActivityCategoriesData().subscribe((activityCategories) => { // MBR0407-1341
                    let idx = 0; // MBR2202-1411
                    const activitiesWithMainOnTop = []; // MBR2202-1411
                    this.activities.forEach((el) => { // MBR0410-1351
                        // enregistrer le dernier élément de chaque catégorie mère pour l'affichage du bouton "+"
                        // enregistrer le nombre d'élement de chaque catégorie -> si > à la limite, on affiche le bouton "+"
                        const motherCat = el.categories.filter((o) => { return o['code'].length === 2; });
                        motherCat.forEach((element) => {
                            const elem = el.categories.filter((o) => {
                                return o['code'].substring(0, 2) === String(element.code) && o['code'].length === 5;
                            });
                            const total = elem.length - 1;
                            const val: any = {
                                code: String(element.code),
                                lastElem: elem[total].code,
                                nbElem: total // MBR0510-1351
                            };
                            // this.codesExpandButton = [...this.codesExpandButton, ...val];
                            this.codesExpandButton.push(val);
                        });

                        // MBR2202-1411 Reorganize activities
                        if (this.mainActivity.length > 0) {
                            if (idx === 0) {
                                this.mainActivity.forEach((act) => {
                                    const a = this.activities.filter((o) => { return String(o['code']) === String(act['idSector']); })[0];
                                    const id = activitiesWithMainOnTop.map(function (d) { return d['code']; }).indexOf(a.code);
                                    if (id < 0) {
                                        activitiesWithMainOnTop.push(a);
                                    }
                                    const ind = this.mainActivity.map(function (d) { return String(d['idSector']); }) // IT/IBI/534
                                        .indexOf(String(el.code));
                                    if (ind < 0) { // IT/IBI/534
                                        activitiesWithMainOnTop.push(el);
                                    }
                                });
                            } else {
                                const ind = this.mainActivity.map(function (d) { return String(d['idSector']); }).indexOf(String(el.code));
                                if (ind < 0) {
                                    activitiesWithMainOnTop.push(el);
                                }
                            }

                            // console.log(idx + ' - ' + this.activities.length);
                            if (idx === this.activities.length - 1) {
                                // console.log(JSON.stringify(this.activities, null, "   "));
                                this.activities = activitiesWithMainOnTop;
                                // console.log(JSON.stringify(this.activities, null, "   "));
                            }

                            idx++;
                        }
                    });

                    if (this.mainActivity.length > 0) {
                        this.forceHide = false; // MBR2202-1411
                        this.onClickEvent = false; // MBR0511-1351 only if forceHide = true
                        this.mainActivity.forEach((element) => { // MBR2206-1331
                            if (element.isMainActivity) {
                                this.toggleSubcat(element.category, element.idSector, true); // MBR0507-1341 : ajout true
                            }

                            // MBR0410-1351 : ajout de l'activité mère
                            // 1) On vérifie que l'activité mère n'est pas déjà présente
                            const ind = this.mainActivity
                                .map(function (d) { return d['category']['code']; })
                                .indexOf(element.category.code.substring(0, 2));
                            if (ind === -1) { // 2) Si l'activité mère n'est pas encore présente dans mainActivity alors on l'ajoute
                                let id = -1;
                                for (let i = 0; i < this.activities.length; i++) { // 2i) on récupère l'index dans activities
                                    if (String(this.activities[i]['code']) === String(element.idSector)) {
                                        id = i;
                                        break;
                                    }
                                }
                                if (id !== -1) {
                                    // 2ii) on ajoute l'activité de activities dans mainActivity (en oubliant pas d'ajouter l'idSector)
                                    const motherCat = this.activities[id].categories
                                        .filter((o) => { return o['code'] === element.category.code.substring(0, 2); });
                                    const mainActByCat = this.mainActivity
                                        .filter((o) => {
                                            return o['category']['code'].substring(0, 2) === element.category.code.substring(0, 2) && o['category']['code'].length === 5;
                                        }); // MBR0510-1351
                                    const mainActWithMotherCat: any = {
                                        isMainActivity: false,
                                        category: motherCat[0],
                                        idSector: element.idSector,
                                        nbElem: mainActByCat.length // MBR0510-1351
                                    };
                                    // this.mainActivity = [...this.mainActivity, ...mainActWithMotherCat];
                                    this.mainActivity.push(mainActWithMotherCat);
                                }
                            }

                            if (element.category.code.length > 5) { // MBR1411-1351 activité principale sur 7 positions
                                // 1) On vérifie que l'activité mère sur 5 positions n'est pas déjà présente
                                const ind5 = this.mainActivity.map(function (d) {
                                    return d['category']['code'];
                                }).indexOf(element.category.code.substring(0, 5));
                                if (ind5 === -1) {
                                    // 2) Si l'activité mère sur 5 positions n'est pas encore présente dans mainActivity alors on l'ajoute
                                    let id = -1;
                                    for (let i = 0; i < this.activities.length; i++) { // 2i) on récupère l'index dans activities
                                        if (String(this.activities[i]['code']) === String(element.idSector)) {
                                            id = i;
                                            break;
                                        }
                                    }
                                    if (id !== -1) {
                                        // 2ii) on ajoute l'activité de activities dans mainActivity (en oubliant pas d'ajouter l'idSector)
                                        const motherCat = this.activities[id].categories.filter((o) => {
                                            return o['code'] === element.category.code.substring(0, 5);
                                        });
                                        const mainActByCat = this.mainActivity.filter((o) => {
                                            return o['category']['code'].substring(0, 5) === element.category.code.substring(0, 5) && o['category']['code'].length === 7;
                                        });
                                        const mainActWithMotherCat: any = {
                                            isMainActivity: false,
                                            category: motherCat[0],
                                            idSector: element.idSector,
                                            nbElem: mainActByCat.length // MBR0510-1351
                                        };
                                        // this.mainActivity = [...this.mainActivity, ...mainActWithMotherCat];
                                        this.mainActivity.push(mainActWithMotherCat);
                                    }
                                }
                            }
                        });
                    } else {
                        this.forceHide = true; // MBR2202-1411
                        this.onClickEvent = true; // MBR0511-1351 only if forceHide = true
                    }
                    // console.log(JSON.stringify(this.codesExpandButton, null, "   "));
                    this.showData();
                });
            });
        });
    }

    public displayExpandButton(code): boolean { // MBR0410-1351
        const elem = this.codesExpandButton.filter((e) => { return e['code'] === code.substring(0, 2); });
        if (elem.length > 0) {
            if (elem[0].nbElem > this.limitCount) { // MBR0510-1351
                if (String(elem[0].lastElem) === String(code)) {
                    if (this.onClickEvent) {
                        return true;
                    } else {
                        const ind = this.mainActivity.map(function (d) { return d['category']['code']; }).indexOf(code.substring(0, 2));
                        if (ind > -1) {
                            if (this.onClickEvent) { // MBR0510-1351
                                return true;
                            } else {
                                if (this.mainActivity[ind].nbElem > this.limitCount) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        } else {
                            return false;
                        }
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    public addWithoutLimit(code) { // MBR0410-1351
        this.codesWithoutLimit.push(code);
    }

    public removeWithoutLimit(code) { // MBR0410-1351
        // console.log(JSON.stringify(this.codesWithoutLimit));
        this.codesWithoutLimit = this.codesWithoutLimit.filter((el) => { return el !== code; });
        // console.log(JSON.stringify(this.codesWithoutLimit));
    }

    public withoutLimitContains(code): boolean { // MBR0410-1351
        if (this.codesWithoutLimit.indexOf(code) > -1) {
            return true;
        } else {
            return false;
        }
    }

    public goOnTop(anchor: string): void { // MBR0510-1351
        (document.querySelector(anchor)).scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    public mothercatContains(cat: any): boolean { // MBR2610-1351
        const nbSubcats7positions = this.subcats.filter((o) => {
            return o['code'].substring(0, 2) === cat['code'].substring(0, 2) && o['code'].length === 7;
        }).length; // MBR1101-1401 : ajout nb sur 7 pos
        const nbSubcats = this.subcats.filter((o) => {
            return o['code'].substring(0, 2) === cat['code'].substring(0, 2) && o['code'].length !== 7;
        }).length; // MBR1101-1401 : ajout test <> 7
        const elem = this.codesExpandButton.filter((o) => {
            return o['code'] === cat['code'].substring(0, 2);
        });
        let nbMax = 0;
        if (elem.length > 0) {
            nbMax = elem[0].nbElem;
        }
        // console.log("nbSubcats : " + nbSubcats + " - max : " + nbMax + " -- nbSubcats7 : " + nbSubcats7positions + " - max : ");
        return ((nbSubcats <= nbMax && (nbSubcats > 0 || nbSubcats7positions > 0))); // MBR1101-1401
    }

    public motherSubcatContains(cat: any, idSector: string): boolean { // MBR1411-1351
        let nbMax = 0;
        let id = -1;
        for (let i = 0; i < this.activities.length; i++) {
            if (String(this.activities[i]['code']) === String(idSector)) {
                id = i;
                break;
            }
        }
        if (id !== -1) {
            // on récupère le nombre max d'activités sur 7 positions dans cette catégorie
            nbMax = this.activities[id].categories.filter((o) => {
                return o['code'].substring(0, 5) === cat.code && o['code'].length === 7;
            }).length;
        }

        // alors on compte le nombre d'activités sur 7 positions sélectionné
        const nbSubcats = this.subcats.filter((o) => { return o['code'].substring(0, 5) === cat.code && o['code'].length === 7; }).length;
        // on compare le nombre d'éléments maximum sur 7 positions avec celles sélectionnées : si = alors faux si < et > à 0 alors vrai
        return (nbSubcats < nbMax && nbSubcats > 0);
    }

    public mainActivityContains(cat: any): boolean {
        const ind = this.mainActivityOriginal.map(function (d) { return d['category']['code']; }).indexOf(cat.code);
        if (ind > -1) {
            return true;
        } else {
            return false;
        }
    }

    public isMainActivity(catCode): boolean { // MBR2202-1411
        const ind = this.mainActivity.map(function (d) { return d['category']['code']; }).indexOf(catCode);

        if (ind < 0) {
            return false;
        } else {
            return true;
        }
    }

    private companyError(error: Response | any) {

        // this.alertService.sendAlert(this.translation
            // .get("Er was een probleem bij het ophalen van de bedrijfsinformatie"), AlertTypes.WARNING, false);
        this.router.navigate(['/risk-calculator']);
        return this.appService.handleError(error);

        // return Observable.throw("error");

    }
    private activitiesError(error: Response | any) { // add by MBR

        // this.alertService.sendAlert(this.translation
            // .get("Er was een probleem bij het ophalen van de activiteiteninformatie"), AlertTypes.WARNING, false);
        this.router.navigate(['/risk-calculator']);
        return this.appService.handleError(error);

        // return Observable.throw("error");

    }
    private retrieveCompanyData(): Observable<Object> {

        if (this.appService.action === 'RESUMEPMECALC') { // MBR2304-1241

            return this.http.get(environment.apiUrl + '/api/simulations/' + this.appService.simulationNumber, this.authService.getHttpOptions())
                .map((companyData) => {
                    this.company = companyData;
                    this.appService.vatNumber = this.company.vatNumber;
                    this.appService.companyName = this.company.name;
                    this.appService.survey = this.company.survey; // MBR2610-1352
                    this.appService.version = this.company.version; // MBR2610-1352
                    // console.log(this.company.id);
                    return companyData;
            }).catch((err) => this.companyError(err));

        } else {

            const vat = this.appService.vatNumber;
            if (vat === null || vat === undefined || vat === '') {
                const err = new Response();
                this.appService.handleError(err);
                this.router.navigate(['/risk-calculator']);
            }
            // let params = new URLSearchParams();
            // params.set('vatNumber', vat);
            // let params = 'vatNumber=' + vat;

            const params: any = {
                vatNumber: vat
            };

            // console.log(params.toString());

            return this.http.post(environment.apiUrl + '/api/simulations', JSON.stringify(params), this.authService.getHttpOptions(true)).map((companyData) => {
                this.company = companyData;
                this.appService.simulationNumber = this.company.id;
                this.appService.companyName = this.company.name;
                this.appService.survey = this.company.survey; // MBR2610-1352
                this.appService.version = this.company.version; // MBR2610-1352
                // console.log(this.company.id);
                return companyData;
            }).catch((err) => this.companyError(err));
        }
    }

    private getMainActivity(): Observable<Object> {

        const simulationID = this.appService.simulationNumber;

        return this.http.get(environment.apiUrl + '/api/simulations/' + simulationID + '/activities', this.authService.getHttpOptions()).map((activities) => {
            this.mainActivity = activities;
            this.mainActivityOriginal = this.mainActivity; // MBR1411-1351
            // console.log(JSON.stringify(this.mainActivity, null, "   "));
            return activities;
        }).catch((err) => this.activitiesError(err));
    }
    private retrieveActivityCategoriesData(): Observable<Response> { // MBR0407-1341 : add Observable
        const simulationID = this.appService.simulationNumber;
        return this.http.get(environment.apiUrl + '/api/simulations/' + simulationID + '/activity-categories', this.authService.getHttpOptions())
            .map((activityCategories) => {
                this.activities = activityCategories;
                // console.log(JSON.stringify(this.activities, null, "   "));
                return this.activities; // MBR0410-1351
        }).catch((err) => this.appService.handleError(err));
    }
    private subcatContains(cat: any): boolean {
        const deepEqual = require('deep-equal'); // MBR2206-1331
        return (this.subcats.filter((o) => { return deepEqual(o, cat); }).length > 0); // MBR2206-1331 : deepEqual
    }
    private toggleSubcat(cat: any, sectorId: string, onInit = false): void { // MBR0507-1341 : ajout onInit
        const deepEqual = require('deep-equal'); // MBR2206-1331
        if (this.subcatContains(cat)) { // MBR0410-1351 si la catégorie est déjà cochée
            if (cat.code.length > 2) {
                const ind = this.mainActivity.map(function (d) {
                    return d['category']['code'];
                }).indexOf(cat.code); // MBR0407-1342 : il faut vérifier si on décoche une activité principale -> sauvegarde
                if (ind !== -1) { // MBR0407-1342
                    const simulationID = this.appService.simulationNumber;
                    /*
                    this.http.delete("/api/simulations/" + simulationID + "/activities/" + this.mainActivity[ind].id,
                        this.authService.getHttpOptions()).map(this.appService.extractData)
                        .catch((err) => this.appService.handleError(err)).subscribe((e) => {
                    console.log(e);
                    });
                    */
                    const unChecked: any = {
                        id: this.mainActivity[ind].id,
                        isMainActivity: false,
                        category: cat,
                        MainEnterpriseId: simulationID
                    };
                    this.mainActivityUnchecked.push(unChecked);
                }
                this.subcats = this.subcats.filter((o) => { return !deepEqual(o, cat); }); // MBR2206-1331 : deepEqual
                // MBR0410-1351 ne pas oublier de décocher le code sur 2 positions -> car toutes les catégories ne seront plus cochées
                this.subcats = this.subcats.filter((o) => { return o['code'] !== cat['code'].substring(0, 2); });

                switch (cat.code.length) { // MBR1411-1351
                    case 5 : this.subcats = this.subcats.filter((o) => { return o['code'].substring(0, 5) !== cat['code']; }); break;
                    case 7 : this.subcats = this.subcats.filter((o) => { return o['code'] !== cat['code'].substring(0, 5); }); break;
                }
                // console.log(JSON.stringify(this.subcats));
            } else {
                // MBR0410-1351 filtrer la liste des subcats filtrées sur les codes commençant par les 2 premiers chiffres
                this.subcats = this.subcats.filter((o) => { return o['code'].substring(0, 2) !== cat['code']; });
            }
        } else {
            if (cat.code.length > 2) { // MBR0410-1351
                // MBR1411-1351 si l'activité est sur 5 positions et qu'elle contient des activités sur 7 positions
                // si oui -> toutes les sélectionner si non -> traitement normal
                const nbElem = this.mainActivityOriginal.filter((o) => {
                    return o['category']['code'].substring(0, 5) === cat['code'] && o['category']['code'].length === 7;
                }).length;
                if (cat.code.length === 5 && nbElem > 0) { // MBR1411-1351
                    let id = -1;
                    for (let i = 0; i < this.activities.length; i++) {
                        if (this.activities[i]['code'] === sectorId) {
                            id = i;
                        }
                    }
                    if (id !== -1) {
                        this.subcats = this.subcats.filter((o) => { return o['code'].substring(0, 5) !== cat['code']; });
                        const subcatsFilter = this.activities[id].categories.filter((o) => {
                            return o['code'].substring(0, 5) === cat['code'] && (o['code'].length === 7 || o['code'].length === 5);
                        });
                        this.subcats = [...this.subcats, ...subcatsFilter];

                        // vérifier si toutes les catégories sur 5 positions ont été cochée, on coche celle sur 2 positions
                        const nbElem2 = this.activities[id].categories.filter((o) => {
                            return o['code'].substring(0, 2) === cat.code.substring(0, 2) && o['code'].length === 5;
                        }).length;
                        const nbSubcats = this.subcats.filter((o) => {
                            return o['code'].substring(0, 2) === cat['code'].substring(0, 2) && o['code'].length === 5;
                        }).length;
                        // console.log("nbElem : " + nbElem2 + " - nbSubcats : " + nbSubcats);
                        if (nbElem2 === nbSubcats) {
                            const subcatsFil = this.activities[id].categories.filter((o) => {
                                return o['code'] === cat['code'].substring(0, 2);
                            });
                            this.subcats = [...this.subcats, ...subcatsFil];
                        }
                    }
                } else {
                    this.subcats.push(cat);
                    let id = -1;
                    for (let i = 0; i < this.activities.length; i++) { // MBR0407-1341
                        if (String(this.activities[i]['code']) === String(sectorId)) {
                            id = i;
                        }
                    }

                    if (cat.code.length === 7) { // MBR1411-1351
                        // vérifier si toutes les catégories "fils" sur 7 positions ont été sélectionnés
                        // -> on coche la catégorie "mère" sur 5 positions
                        const nbElem7 = this.activities[id].categories.filter((o) => {
                            return o['code'].substring(0, 5) === cat.code.substring(0, 5) && o['code'].length === 7;
                        }).length;
                        const nbSubcats = this.subcats.filter((o) => {
                            return o['code'].substring(0, 5) === cat['code'].substring(0, 5) && o['code'].length === 7;
                        }).length;
                        if (nbElem7 === nbSubcats) {
                            if (id !== -1) {
                                const subcatsFilter = this.activities[id].categories.filter((o) => {
                                    return o['code'] === cat['code'].substring(0, 5);
                                });
                                this.subcats = [...this.subcats, ...subcatsFilter];
                            }
                        }
                    }
                    // MBR2610-1351 : vérifier si toutes les catégories "fils" ont été sélectionné -> on coche la catégorie "mère"
                    const elem = this.codesExpandButton.filter((o) => {
                        return (o['code'] === cat['code'].substring(0, 2)) && o['code'].length !== 7;
                    }); // MBR1101-1401 : test si longueur <> 7 car si on coche 5 pos + 7 pos -> bug d'affichage de la box sur 2 pos
                    if (elem.length > 0) {
                        const nbSubcats = this.subcats.filter((o) => {
                            return (o['code'].substring(0, 2) === cat['code'].substring(0, 2)) && o['code'].length !== 7;
                        }).length; // MBR1101-1401
                        if (nbSubcats > elem[0].nbElem) { // MBR2610-1351
                            if (id !== -1) {
                                const subcatsFilter = this.activities[id].categories.filter((o) => {
                                    return o['code'] === cat['code'].substring(0, 2);
                                });
                                this.subcats = [...this.subcats, ...subcatsFilter];
                            }
                        }
                    }

                    if (id !== -1) {
                        if (this.activities[id].hasPopup && !onInit) { // MBR0507-1341
                            this.openDialog(id);
                            // this.popupAlreadyShown = true; // MBR0507-1341
                        }
                    }
                }
            } else { // MBR0410-1351
                // si l'utilisateur sélectionne une activité avec un code sur 2 positions
                // filtrer la liste des activités (activities) sur le sectorId
                // MBR2610-1351 : retirer toutes les catégories "fils" déjà présentent dans subcats
                // ajouter le contenu de categories
                let id = -1;
                for (let i = 0; i < this.activities.length; i++) { // MBR0407-1341
                    if (this.activities[i]['code'] === sectorId) {
                        id = i;
                    }
                }
                if (id !== -1) {
                    this.subcats = this.subcats.filter((o) => { return o['code'].substring(0, 2) !== cat['code']; }); // MBR2610-1351
                    const subcatsFilter = this.activities[id].categories.filter((o) => {
                        return o['code'].substring(0, 2) === cat['code'] && (o['code'].length === 5 || o['code'].length === 2);
                    }); // MBR2610-1351 : length = 5 et 2
                    this.subcats = [...this.subcats, ...subcatsFilter];
                }
            }
        }
        // console.log(JSON.stringify(this.subcats, null, "   "));
    }
    private openDialog(idTabSector = -1): void {
        if (idTabSector === -1) {
            const currentActivity = this.mainActivity;
            const currentIndexRepa = currentActivity.map(function (d) { return d['hasPopup']; }).indexOf(8);
            const currentIndexAgri = currentActivity.map(function (d) { return d['idSector']; }).indexOf(1);
            if (currentIndexRepa > -1) {
                // this.popupDial.title = this.activities[7].popupTitle;
                // this.popupDial.description = this.activities[7].popupDescription;
                this.appService.popupTitle = this.activities[7]['popupTitle' + this.getLang()];
                this.appService.popupText = this.activities[7]['popupDescription' + this.getLang()];
                this.dialog.open(BetterProposalModalComponent);
                // this.popupAlreadyShown = true; // MBR0507-1341
            } else {
                if (currentIndexAgri > -1) {
                    // this.popupDial.title = this.activities[0].popupTitle;
                    // this.popupDial.description = this.activities[0].popupDescription;
                    this.appService.popupTitle = this.activities[0]['popupTitle' + this.getLang()];
                    this.appService.popupText = this.activities[0]['popupDescription' + this.getLang()];
                    this.dialog.open(BetterProposalModalComponent);
                    // this.popupAlreadyShown = true; // MBR0507-1341
                }
            }
        } else {
            this.appService.popupTitle = this.activities[idTabSector]['popupTitle' + this.getLang()];
            this.appService.popupText = this.activities[idTabSector]['popupDescription' + this.getLang()];
            this.dialog.open(BetterProposalModalComponent);
            // this.popupAlreadyShown = true; // MBR0507-1341
        }
        /* console.log(this.company);
        console.log(this.activities); */
    }
    private showData(): void {
        const self = this;
        setTimeout(function () {
            // self.openDialog(); // MBR0507-1341
            self.preloadHeader.nativeElement.classList.add('fadeOut');
            self.loadedHeader.nativeElement.classList.add('fadeInUp');
            self.companyActivity.nativeElement.classList.add('fadeInUp');
            self.companyGeneral.nativeElement.classList.add('fadeInUp');
            self.companyContact.nativeElement.classList.add('fadeInUp');
            self.companyLocation.nativeElement.classList.add('fadeInUp');
            self.continue.nativeElement.classList.add('fadeInUp');
            self.dataLoaded = true;
        }, 2000);
    }

    private showContent(index: number, indexOfActivity = -1): boolean {
        let returnValue = false;

        if (index === -1) {
            this.onClickEvent = !this.onClickEvent;
        }
        if (this.onClickEvent) {
            // - toon minder activiteiten
            returnValue = this.displayWithLimit(indexOfActivity); // MBR0410-1351
        } else {
            // + toon meer
            if (this.mainActivity.length > 0) {
                const currentActivity = this.mainActivity;
                const currentIndex = currentActivity.map(function (d) { return d['idSector']; }).indexOf(+index);
                // console.log(JSON.stringify(currentActivity, null, "   "));
                if (currentIndex > -1) {
                    if (indexOfActivity > -1) {
                        const currentIndexOfActivity = currentActivity.map(function (d) {
                            return d['category']['code'];
                        }).indexOf(indexOfActivity);
                        if (currentIndexOfActivity > -1) {
                            returnValue = true; // pose problème avec l'affichage des catégories sur 7 positions :
                                                // this.displayWithLimit(indexOfActivity); // MBR0410-1351
                        } else {
                            returnValue = false;
                        }
                    } else {
                        returnValue = true;
                    }
                } else {
                    returnValue = false;
                }
            } else {
                returnValue = false;
            }
        }

        return returnValue;
    }

    private displayWithLimit(indexOfActivity): boolean { // MBR0410-1351
        if (String(indexOfActivity).length === 5) {
            if (this.limit.length > 0) {
                const id = this.limit.map(function (d) { return d['sector']; }).indexOf(String(indexOfActivity).substring(0, 2));
                if (id !== -1) {
                    const idAct = this.limit.map(function (d) { return d['code']; }).indexOf(String(indexOfActivity));
                    if (idAct !== -1) {
                        return true;
                    } else {
                        if (this.codesWithoutLimit.indexOf(String(indexOfActivity).substring(0, 2)) > -1) {
                            /* add to limit with no count
                            let val: any = {
                                sector: String(indexOfActivity).substring(0, 2),
                                code: String(indexOfActivity),
                                count: 0
                            };
                            this.limit = [...this.limit, ...val]; */
                            return true;
                        } else {
                            if (this.limit[id].count < this.limitCount) { // MBR0510-1351
                                this.limit[id].count++;
                                const val: any = {
                                    sector: String(indexOfActivity).substring(0, 2),
                                    code: String(indexOfActivity),
                                    count: 0
                                };
                                // this.limit = [...this.limit, ...val];
                                this.limit.push(val);
                                return true;
                            } else {
                                // variable dynamique pour afficher le +
                                return false;
                            }
                        }
                    }
                } else {
                    const val: any = {
                        sector: String(indexOfActivity).substring(0, 2),
                        code: String(indexOfActivity),
                        count: 0
                    };
                    // this.limit = [...this.limit, ...val];
                    this.limit.push(val);
                    return true;
                }
            } else {
                const val: any = {
                    sector: String(indexOfActivity).substring(0, 2),
                    code: String(indexOfActivity),
                    count: 0
                };
                this.limit.push(val);
                return true;
            }
        } else {
            return true;
        }
    }

    private updateActivities(): Observable<Object> {
        const simulationID = this.appService.simulationNumber;
        const mainActivityPost = this.mainActivityUnchecked; // MBR0407-1342
        // console.log(JSON.stringify(mainActivityPost, null, "   "));
        for (const cat of this.subcats) {
            // console.log(JSON.stringify(cat));
            const currentActivity = this.mainActivity;
            // let code = cat['code'];
            const currentIndex = currentActivity.map(function (d) { return d['category']['code']; }).indexOf(cat['code']);
            // console.log(cat['code']);
            let idAct = null;

            if (currentIndex > -1) {
                idAct = this.mainActivity[currentIndex].id;
            }

            const selectedValue: any = {
                id: idAct,
                isMainActivity: true,
                category: cat,
                MainEnterpriseId: simulationID
            };
            mainActivityPost.push(selectedValue);
        }

        // console.log(JSON.stringify(mainActivityPost, null, "   "));
        return this.http.post(environment.apiUrl + '/api/simulations/' + simulationID + '/activities',
            JSON.stringify(mainActivityPost),
            this.authService.getHttpOptions(true)).map((activities) => {
                return activities;
        }).catch((err) => this.appService.handleError(err)); // this.activitiesError(err));
    }

    private definePrimary(): void {
        // console.log(this.subcats);
    }

    private getLang(): string {
        const lang = this.appService.getLanguage();
        return lang.charAt(0).toUpperCase() + lang.slice(1).toLowerCase();
    }

    public unloadPage(): void {

        this.goOnTop('body');

        if (this.dataCompanyInserted === true) { // CSA0604-1201
            this.updateCompanyData();
        }

        if (this.subcats.length > 0) {
            const self = this;
            // this.updateActivities(); // MBR1604-1202
            this.renderer.listen(this.loadedHeader.nativeElement, 'transitionend', () => {
                this.updateActivities().subscribe((Data) => {
                    let ok = Data['_body']; // MBR1604-1202 // MBR0106-1301 : ajout _body
                    ok = JSON.parse(ok); // MBR0106-1301
                    if (ok['done'] === true) { // MBR0106-1301
                        this.wizardService.getFirstQuestion(1).subscribe((Q) => { // MBR2004-1232
                            if (Q['id'] > 1) {
                                self.router.navigate(['./company-information/additional']);
                            } else {
                                this.loadScreen(self);
                                this.hasErrorUpt = true;
                                this.hasErrorKBO = false;
                                this.errorTxtUpt = 'Get next question Error';
                            }
                        });
                    } else { // MBR2004-1232
                        this.loadScreen(self);
                        this.hasErrorUpt = true;
                        this.hasErrorKBO = false;
                        this.errorTxtUpt = 'Update activities Error';
                    }
                });
            });
            this.renderer.listen(this.loadedHeader.nativeElement, 'webkitTransitionEnd', () => {
                this.updateActivities().subscribe((Data) => {
                    let ok = Data['_body']; // MBR1604-1202 // MBR0106-1301: ajout _body
                    ok = JSON.parse(ok); // MBR0106-1301
                    if (ok['done'] === true) { // MBR0106-1301
                        this.wizardService.getFirstQuestion(1).subscribe((Q) => { // MBR2004-1232
                            if (Q['id'] > 1) {
                                self.router.navigate(['./company-information/additional']);
                            } else {
                                this.loadScreen(self);
                                this.hasErrorUpt = true;
                                this.hasErrorKBO = false;
                                this.errorTxtUpt = 'Get next question Error';
                            }
                        });
                    } else { // MBR2004-1232
                        this.loadScreen(self);
                        this.hasErrorUpt = true;
                        this.hasErrorKBO = false;
                        this.errorTxtUpt = 'Update activities Error';
                    }
                });
            });
            this.renderer.listen(this.loadedHeader.nativeElement, 'animationend', () => {
                this.updateActivities().subscribe((Data: any) => {
                    // const ok = Data['_body']; // MBR1604-1202 // MBR0106-1301: ajout _body
                    // ok = JSON.parse(ok); // MBR0106-1301
                    if (Data.done === true) { // MBR0106-1301
                        this.wizardService.getFirstQuestion(1).subscribe((Q) => { // MBR2004-1232
                            if (Q['id'] > 1) {
                                self.router.navigate(['./company-information/additional']);
                            } else {
                                this.loadScreen(self);
                                this.hasErrorUpt = true;
                                this.hasErrorKBO = false;
                                this.errorTxtUpt = 'Get next question Error';
                            }
                        });
                    } else { // MBR2004-1232
                        this.loadScreen(self);
                        this.hasErrorUpt = true;
                        this.hasErrorKBO = false;
                        this.errorTxtUpt = 'Update activities Error';
                    }
                });
            });

            this.waitScreen(); // MBR2004-1232
        } else {
            this.hasErrorKBO = true;
            (document.querySelector('#error')).scrollIntoView({ behavior: 'smooth', block: 'start' });
            // this.alertService.sendAlert(this.translation.get(
            // "U moet ten minste één activiteit te selecteren"), AlertTypes.WARNING, false);
        }

    }

    private loadScreen(self): void { // MBR2004-1232
        self.preloadHeader.nativeElement.classList.add('fadeOut');
        self.loadedHeader.nativeElement.classList.remove('fadeOut');
        self.companyActivity.nativeElement.classList.remove('fadeOut');
        self.companyGeneral.nativeElement.classList.remove('fadeOut');
        self.companyContact.nativeElement.classList.remove('fadeOut');
        self.companyLocation.nativeElement.classList.remove('fadeOut');
        self.continue.nativeElement.classList.remove('fadeOut');
    }

    private waitScreen(): void { // MBR2004-1232
        this.loadedHeader.nativeElement.classList.add('fadeOut');
        this.companyInformation.nativeElement.classList.add('fadeOut');
        this.preloadHeader.nativeElement.classList.remove('fadeOut');
        // this.preloadHeader.nativeElement.classList.add('fadeInUp');
        this.companyActivity.nativeElement.classList.add('fadeOut');
        this.companyGeneral.nativeElement.classList.add('fadeOut');
        this.companyContact.nativeElement.classList.add('fadeOut');
        this.companyLocation.nativeElement.classList.add('fadeOut');
        this.continue.nativeElement.classList.add('fadeOut');
    }

    private updateCompanyData(): void { // CSA0604-1201

        const simulationID = this.appService.simulationNumber;

        this.http.post(environment.apiUrl + '/api/simulations/' + simulationID, JSON.stringify(this.company), this.authService.getHttpOptions(true))
            .map((dataCompany) => {
                this.company = dataCompany;
                // console.log("Debug: updateCompanyData() : " + JSON.stringify(this.company, null, "   "));
            })
            .catch((err) => this.appService.handleError(err)).subscribe();
    }

    public onInsertDataCompany($event: any): void { // CSA0604-1201

        if (this.dataCompanyInserted === false) {
            this.dataCompanyInserted = true;
        }
        // console.log($event);
        switch ($event.field) {
            case 'company.phone': {
                this.company.phone = $event.value;
                break;
            }
            case 'company.companyEmail': {
                this.company.companyEmail = $event.value;
                break;
            }
            case 'company.personalEmail': {
                this.company.personalEmail = $event.value;
                break;
            }
            case 'company.website': {
                this.company.website = $event.value;
                break;
            }
            default: {
                break;
            }
        }
        // console.log(JSON.stringify(this.company));
    }

    @HostListener('document:keydown', ['$event'])
    private onKeyDown(event): void {
        if (event.key === 'Enter' && this.dataLoaded) {
            this.unloadPage();
        }
    }

    @HostListener('window:scroll', []) // MBR0510-1351
    private onWindowScroll() {
        if (window.scrollY >= 200) {        // If page is scrolled more than 50px
            this.displayArrowTop = true;   // Fade in the arrow
        } else {
            this.displayArrowTop = false;   // Else fade out the arrow
        }
    }
}
