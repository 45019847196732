import { NgModule } from '@angular/core';

import {
  MatButtonModule,
  MatAutocompleteModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatDatepickerModule,
  MatCardModule
} from '@angular/material';

@NgModule({
  imports: [
    MatButtonModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatDatepickerModule,
    MatCardModule
  ],
  exports: [
    MatButtonModule,
    MatAutocompleteModule,   
    MatMenuModule,
    MatToolbarModule,
    MatDatepickerModule,    
    MatIconModule,
    MatCardModule
  ]
})
export class MaterialModule {}
