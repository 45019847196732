import { Injectable } from '@angular/core';
import { CustomTranslateService } from './shared/services/custom-translate.service';

@Injectable()
export class Validations {

    public validationMessages: any = {
        enterpriseNumber: {
            required: 'Enterprise Number is required.',
            pattern: 'Enterprise Number is not in the correct format.'
        },
        vatNumber: {
            required: 'VAT number is required.',
            pattern: 'VAT number is not in the correct format.'
        },
        legalForm: {
            required: 'Legal form is required.'
        },
        name: {
            required: 'Name is required.'
        },
        firstName: {
            required: 'First name is required.'
        },
        legislation: {
            required: 'Legislation is required.'
        },
        street: {
            required: 'Street is required.'
        },
        number: {
            required: 'Number is required.'
        },
        city: {
            required: 'City is required.'
        },
        postal_code: {
            required: 'Postal code is required.'
        },
        country: {
            required: 'Country is required.'
        },
        contactValue: {
            required: 'Contact value is required.',
            pattern: 'Contact value is not in the correct format.'
        },
        definition: {
            required: 'Definition is required.',
        },
        start: {
            dateRangeError: 'Mandate overlaps with other mandate',
        },
        end: {
            dateRangeError: 'Mandate overlaps with other mandate',
            minDateError: 'End date should come after start date',
            maxDateError: 'Date exceeds the maximum date'
        },
        mandateHolder: {
            noPersonAtEnd: 'Holder must be represented by a person',
            entityInHolder: 'Entity can not be mandate holder in itself'
        },
        default: {
            required: this.translate.get("Dit veld is verplicht"),
            email: this.translate.get("Dit is geen geldig e-mailadres"),
            pattern: this.translate.get("Het formaat van dit veld is niet correct"),
        }
    };
    constructor(
        public translate: CustomTranslateService
    ) { }
}
