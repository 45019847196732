import { Input, Component, OnDestroy } from '@angular/core';
import { AlertService } from '../../services';
import { Subscription } from 'rxjs/Subscription';
import { Router, NavigationStart } from '@angular/router';
import { Alert } from '../../models';

@Component({
  selector: 'ngbd-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class NgbdAlertComponent implements OnDestroy {
  @Input()
  public tempAlerts: Alert[] = [];
  public permAlerts: Alert[] = [];
  private subscription: Subscription;

  constructor(private alertService: AlertService, private router: Router) {
    this.subscription = this.alertService.getAlert().subscribe((alert) => {
      if (alert.temporary) {
        if (this.tempAlerts.length >= 1) {
          let currentAlert = this.tempAlerts[this.tempAlerts.length - 1];
          currentAlert.message = alert.message;
          currentAlert.type = alert.type;
        } else {
          this.tempAlerts.push(alert);
        }
        setTimeout(() => this.closeAlert(alert), 3000);
      } else {
        if (this.permAlerts.length >= 1) {
          let currentAlert = this.permAlerts[this.permAlerts.length - 1];
          currentAlert.message = alert.message;
          currentAlert.type = alert.type;
        } else {
          this.permAlerts.push(alert);
        }
      }
    });
  }

  public closeAlert(alert: Alert) {
    let index = this.permAlerts.findIndex((al) => al.message === alert.message);
    if (index === -1) {
      index = this.tempAlerts.findIndex((al) => al.message === alert.message);
      this.tempAlerts.splice(index, 1);
    } else {
      this.permAlerts.splice(index, 1);
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public alertsVisible(): boolean {
    if (this.permAlerts.length > 0 || this.tempAlerts.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
