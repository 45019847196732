import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { AppService } from '../../../../app.service';
import { Question } from '../../../models/question';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { WizardQuestionComponent } from '../../../../public/wizard/question/index';

@Component({
  selector: 'app-question-type2',
  styleUrls: ['./question-type2.component.scss'],
  templateUrl: './question-type2.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionType2Component),
      multi: true
    },
  ]
})

export class QuestionType2Component implements ControlValueAccessor {
  @Output()

  private notifyResponse: EventEmitter<any> = new EventEmitter();

  private currentValue = -1;
  private currentAnswer = '';
  private currentAnswerPOST: any = [];
  @Input()
  public _question: Question;

  constructor(
    private appService: AppService,
    private wizardQuestion: WizardQuestionComponent
  ) {
  }

  public writeValue(value: any): void {
    if (value) {
      this._question = value;
      this.setInitial();
    }
  }
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  public registerOnTouched(fn: any): void {
    // Empty
  }
  public setDisabledState?(isDisabled: boolean): void {
    // Empty
  }
  public get question() {
    return this._question;
  }
  public set question(val) {
    this._question = val;
    this.propagateChange(val);
  }
  private setInitial(): void {
    if (this._question.selectedValue) {
      // console.log("this._question.values : " + JSON.stringify(this._question.values, null, "   "));
      // console.log("this._question.selectedValue : " + JSON.stringify(this._question.selectedValue, null, "   "));
      // let initialValue = this._question.selectedValue;
      // this.setCurrentValue(this._question.values['id'].indexOf(initialValue['id']));
      if (this._question.selectedValue[0] === undefined) {
        const index = this._question.values.map(function(d) { return d['id']; }).indexOf(this._question.selectedValue.id);
        this.setCurrentValue(index);
      } else {
        const index = this._question.values.map(function(d) { return d['id']; }).indexOf(this._question.selectedValue[0].id);
        this.setCurrentValue(index);
      }
    }
  }
  private propagateChange = (_: any) => {    // Empty
  }
  private notifyParent() {
    if (this._question.continueOnClick) {
      this.notifyResponse.emit();
    }
  }
  private setCurrentValue(value: number): void {
    // console.log(value);
    this.currentValue = value;
    // this.question.values[value].selected = !this.question.values[value].selected;
    this.currentAnswer = this.question.values[value];
    this.currentAnswerPOST = this.question.values;
    this.question.selectedValue = this.currentAnswer;
    // this.notifyParent(); // MBR0405-1251
  }

  private unloadPage(): void {
    this.wizardQuestion.unloadForNextQuestion();
  }

  private getLang(): string {
    const lang = this.appService.getLanguage();
    return lang.charAt(0).toUpperCase() + lang.slice(1).toLowerCase();
  }
}
