import { Component, Input, forwardRef } from '@angular/core';
import { AppService } from '../../../../app.service';
import { Question } from '../../../models/question';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormGroup, FormControl } from '@angular/forms';
import { WizardQuestionComponent } from '../../../../public/wizard/question/index';

@Component({
  selector: 'app-question-type4',
  styleUrls: ['./question-type4.component.scss'],
  templateUrl: './question-type4.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionType4Component),
      multi: true
    },
  ]
})

export class QuestionType4Component implements ControlValueAccessor {

  public currentValue = -1;
  public currentAnswers: string[] = [];
  @Input()
  public _question: Question;
  private clickPasDeVehicules = false; // MBR0705-1252
  /* FLOTTE
  private answers: string[] = [];

  private flotteForm = new FormGroup({
    _1: new FormControl(''),
    _2: new FormControl(''),
    _3: new FormControl(''),
    _4: new FormControl('')
  });
  */

  constructor(
    private appService: AppService,
    private wizardQuestion: WizardQuestionComponent
  ) {
    /* FLOTTE
    this.flotteForm.controls['_1'].setValue(this.flotteForm.controls['_1'].value);
    this.flotteForm.controls['_2'].setValue(this.flotteForm.controls['_2'].value);
    this.flotteForm.controls['_3'].setValue(this.flotteForm.controls['_3'].value);
    this.flotteForm.controls['_4'].setValue(this.flotteForm.controls['_4'].value);
    */
   }

  public writeValue(value: any): void {
    if (value) {
      this._question = value;
      this.setInitial();
    }
  }
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  public registerOnTouched(fn: any): void {
    // Empty
  }
  public setDisabledState?(isDisabled: boolean): void {
    // Empty

  }
  public get question() {
    return this._question;
  }

  public set question(val) {
    this._question = val;
    this.propagateChange(val);
  }
  private propagateChange = (_: any) => {    // Empty
  }
  private setInitial(): void {
    if (this._question.selectedValue) {
      this.currentAnswers = this._question.selectedValue;
    }
  }
  private toggleValue(answer: any): void {

    const value = answer.text;
    let currentAnswers = this.currentAnswers;
    const currentIndex = currentAnswers.indexOf(value);
    // let answersPost: any = [];
    answer.selected = !answer.selected;
    if (currentIndex < 0) {
      if (answer.id === 440) { // MBR0705-1252
        currentAnswers = [];
        this.clickPasDeVehicules = true;
      } else {
        if (this.clickPasDeVehicules) {
          currentAnswers = [];
          this.clickPasDeVehicules = false;
        }
      }
      currentAnswers.push(value);
      // FLOTTE this.answers.push(answer);
      // answersPost.push(answer);
    } else {
      currentAnswers = currentAnswers.filter((val) => val !== value);
      // FLOTTE this.answers = this.answers.filter((val) => val !== answer);
      // answersPost = answersPost.filter((val) => val.id !== answer);
    }
    this.currentAnswers = currentAnswers;
    this._question.selectedValue = currentAnswers;
  }
  private isSelected(value: any): boolean {
    const selected = false;
    const currentValues = this.currentAnswers;
    if (currentValues.length === 0) {
      return false;
    }
    return (currentValues.indexOf(value) > -1);
  }
  private unloadPage(): void {
    this.wizardQuestion.unloadForNextQuestion();
  }

  private getLang(): string {
    const lang = this.appService.getLanguage();
    return lang.charAt(0).toUpperCase() + lang.slice(1).toLowerCase();
  }
}
