import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {

  public transform(items: any[], key: string, key2: string, searchText: string): any[] { // MBR2910-1351
    if (!items) {
        return [];
    }
    if (!searchText) {
        return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter( (it) => {
      return it[key].toLowerCase().includes(searchText) || it[key2].toLowerCase().includes(searchText); // MBR2910-1351
    });
   }
}
