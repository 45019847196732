export class Question {
    public id: number;
    public title: string;
    public helptext: string;
    public type: number;
    public category: string;
    public onlyNumbers: boolean;
    public userInput: string;
    public min: string;
    public max: string;
    public values: any[];
    public continueOnClick = false;
    public selectedValue: any;

}
