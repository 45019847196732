import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pagination',
  template: `<ngb-pagination
    (pageChange)="pageChangeProxy($event)"
    [pageSize]="pageSize"
    [collectionSize]="collectionSize"
    [(page)]="page"
    [directionLinks]="false"
    [maxSize]="7"
    [rotate]="true"></ngb-pagination>`
})
export class PaginationComponent {
  @Output() public pageChange = new EventEmitter<number>();
  @Input() public pageSize: number;
  @Input() public collectionSize: number;
  @Input() public page: number;

  public pageChangeProxy(page: number) {
    this.pageChange.emit(page);
  }
}
