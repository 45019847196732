import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/Observable";
import { Map } from "rxjs/Map";

@Pipe({ name: 'num', pure: false })
export class NumberPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
        this.sanitizer = sanitizer;
    }

    public transform(stringNumber, precision: number = 2, round: boolean = true) { // MBR1704-1211

        if (stringNumber !== null) {

            if (round) { // MBR1704-1211
                let parsed = parseInt(stringNumber, 10);
                if (parsed) {
                    return parsed.toFixed(precision);
                }
            } else { // MBR1704-1211
                let parsed = parseFloat(stringNumber);
                if (parsed) {
                    return parsed.toFixed(precision);
                }
            }
        }
        return 0;
    }
}
