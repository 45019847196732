import { Injectable } from '@angular/core';
import { NgbdAlertComponent } from './alert.component';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs';
import { Alert, AlertTypes } from './../models';

@Injectable()
export class AlertService {
    private _alerts = new Subject<Alert>();

    public sendAlert(message: string, alertType: AlertTypes, temporary: Boolean) {
        let type: string;
        switch (alertType) {
            case AlertTypes.SUCCESS:
                type = 'success';
                break;
            case AlertTypes.INFO:
                type = 'info';
                break;
            case AlertTypes.WARNING:
                type = 'warning';
                break;
            case AlertTypes.DANGER:
                type = 'danger';
                break;
            default:
                type = 'default';
                break;
        }
        let alert = { type, message, temporary };

        this._alerts.next(alert);
    }
    public getAlert(): Observable<Alert> {
        return this._alerts.asObservable();
    }
}
