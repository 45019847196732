import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { AuthService } from '../../auth/auth.service';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
    jsonCreateObject: {
        vatNumber: string, userId: string, cli: string, portfolioId: number, contributorId: number, clientId: number, language: string
    };
    id: number;
    type: string;
    progressValue: number;
    progressText: string;
    constructor(
        private appService: AppService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.progressText = 'Creating token';
        this.progressValue = 0;
        this.route.queryParams.subscribe(params => {
            this.type = params['type'];
            if (params['type'] === 'create') {
                this.jsonCreateObject = {
                    vatNumber: params['vatNumber'],
                    userId: params['userId'],
                    cli: params['cli'],
                    portfolioId: params['portfolioId'],
                    contributorId: params['contributorId'],
                    clientId: params['clientId'],
                    language: params['language']
                };
            } else {
                this.id = Number(params['simulation']);
            }
        });

        this.authService.verifyToken().subscribe((secureTokenAPI) => {
            if (this.type === 'create') {
                let language = 'fr';
                if (this.jsonCreateObject.language === 'N') {
                    language = 'nl';
                }
                this.appService.vatNumber = this.jsonCreateObject.vatNumber;
                this.appService.setLanguage(language);
                this.authService.createToken(this.jsonCreateObject)
                    .subscribe(resp => {
                        if (resp['redirectUrl'] !== '') {
                            window.location.href = resp['redirectUrl'];
                        } else {
                            this.progressText = 'Creating simulation';
                            // this.authService.setToken(resp['token']);
                            this.appService.action = resp['action'];
                            this.authService.createSimulation(this.jsonCreateObject)
                                .subscribe(simulation => {
                                    this.progressText = 'Redirecting';
                                    this.appService.simulationNumber = simulation['id'];
                                    this.appService.companyName = simulation['name'];
                                    this.appService.survey = simulation['survey'];
                                    this.appService.version = simulation['version'];
                                    this.router.navigate(['company-information']);
                                }, err => {
                                    this.progressText = err.error.Message;
                                });
                        }
                    }, err => {
                        console.log(err);
                        this.progressText = err.error.Message;
                    });
            } else {
                this.authService.createResumeToken(this.id)
                    .subscribe(token => {
                        if (token['redirectUrl'] !== '') {
                            window.location.href = token['redirectUrl'];
                        } else {
                            this.progressText = 'Redirecting';
                            let language = 'fr';
                            if (token['language'] === '2') {
                                language = 'nl';
                            }
                            // this.authService.setToken(token['token']);
                            this.appService.setLanguage(language);
                            this.appService.simulationNumber = this.id.toString();
                            this.router.navigate(['./wizard/result']);
                        }
                    }, err => {
                        console.log(err);
                        this.progressText = err.error.Message;
                    })
            }
        },
        (error) => {
            console.log(error);
        });
    }

}
