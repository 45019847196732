import { Component, Output, ViewChild, Renderer2, HostListener, EventEmitter, Inject } from '@angular/core';
import { NgControl, FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertTypes } from '../../../../models/index';

@Component({ // CSA1309-1352
    selector: 'question5-form-address',
    styleUrls: ['./question-type5-form-address.component.scss'],
    templateUrl: './question-type5-form-address.component.html',
})

export class QuestionType5FormAddressComponent {
    @Output('change') public change: EventEmitter<any> = new EventEmitter<any>();
    public myForm: FormGroup;

    constructor(
        private fb: FormBuilder
    ) {
        this.myForm = fb.group({
            buildingStreet: [''],
            buildingNumber: [''],
            buildingZipcode: ['', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
            buildingCity: ['']
        });
    }

    public SaveChanges() {
        let data = {
            ret: true,
            buildingStreet: this.myForm.controls['buildingStreet'].value,
            buildingNumber: this.myForm.controls['buildingNumber'].value,
            buildingZipcode: this.myForm.controls['buildingZipcode'].value,
            buildingCity: this.myForm.controls['buildingCity'].value
        };
        
        this.change.emit(data);
        this.setValues();
    }

    public CancelAdding() {
        this.change.emit({
            ret: false
        });
        this.setValues();
    }

    public validateInput() { // for validation in safari web browser
        this.myForm.patchValue({ ['buildingZipcode']: this.myForm.controls['buildingZipcode'].value });
    }
    
    private setValues(): void {
        this.myForm.get(['buildingStreet']).setValue('');
        this.myForm.get(['buildingNumber']).setValue('');
        this.myForm.get(['buildingZipcode']).setValue('');
        this.myForm.get(['buildingCity']).setValue('');
    }
}
