import { Component, OnInit, ViewChild, ElementRef, Renderer2, HostListener, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../../app.service';
import { AlertService, CustomTranslateService } from '../../../shared/services/index';
import { Http } from '@angular/http';
import { AuthService } from '../../../auth/index';
import { Observable } from 'rxjs/Rx';
import { AlertTypes } from '../../../shared/models/index';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-additional-companies',
  styleUrls: ['./additional-companies.component.scss'],
  templateUrl: './additional-companies.component.html'
})
export class AdditionalCompaniesComponent implements AfterViewInit {

  @ViewChild('loadedHeader', {static: false})
  private loadedHeader: ElementRef;
  @ViewChild('companyInformation', {static: false})
  private companyInformation: ElementRef;
  @ViewChild('addCompanyForm', {static: false})
  private addCompanyForm: ElementRef;
  @ViewChild('loadingInformation', {static: false})
  private loadingInformation: ElementRef;
  public companies: any = [];
  private addCompanyResult: any = {};
  public companyNumber = '';
  // private companyName: string = ""; // MBR0901-1401
  private subcat = 41;
  private dataLoaded = false;
  private listener1: any;
  private listener2: any;
  private listener3: any;
  private didLoadAlready = false;
  private resultCode: number;
  private resultMessage: string;
  // private hasError: boolean = false; // MBR0901-1401
  // private hasErrorText: string = ""; // MBR0901-1401

  constructor(
    public appService: AppService, // MBR0901-1401
    private renderer: Renderer2,
    private router: Router,
    private authService: AuthService,
    private translation: CustomTranslateService,
    private http: HttpClient,
    private alertService: AlertService
  ) { }

  public ngAfterViewInit(): void {
    const self = this;
    const simID = this.appService.simulationNumber;
    /* MBR0901-1401
    setTimeout(() => {
      // let err = JSON.parse(error); // MBR0608-135
      this.hasError = this.appService.catchError;
      this.hasErrorText = this.appService.errorText['Message'];
      this.appService.catchError = false;
      this.appService.errorText = "";
      this.companyName = this.appService.companyName;
    }, 0);
    */
    if (simID === null || simID === undefined || simID === '') {
      this.router.navigate(['/risk-calculator']);
    } else {
      this.getCompanies(simID);

      this.renderer.listen(this.companyInformation.nativeElement, 'transitionend', () => {
        self.dataLoaded = true;
      });
      this.renderer.listen(this.companyInformation.nativeElement, 'webkitTransitionEnd', () => {
        self.dataLoaded = true;
      });
      this.renderer.listen(this.companyInformation.nativeElement, 'animationend', () => {
        self.dataLoaded = true;
      });
    }
  }
  private removeBindingsLoad(): void {
    this.listener1();
    this.listener2();
    this.listener3();
  }
  private deleteCompany(companyId): void {
    const simulationID = this.appService.simulationNumber;
    const index = this.companies.map(function(d) { return d['id']; }).indexOf(companyId);
    this.companies.splice(index, 1);
    // console.log(companyId);
    this.appService.catchError = false; // MBR0901-1401
    this.appService.errorText = ''; // MBR0901-1401
    this.http.delete(environment.apiUrl + '/api/simulations/' + simulationID + '/companies/' + companyId, this.authService.getHttpOptions())
      .catch((err) => this.appService.handleError(err)) // MBR0901-1401
      .subscribe((e) => {
      // this.companies.splice(-1, 1);
      // console.log(e);
    });
  }
  private addBindingsLoad(): void {
    const self = this;
    setTimeout(function () {
      self.loadingInformation.nativeElement.classList.add('fadeOutUp');
      self.didLoadAlready = true;
      self.addCompanyForm.nativeElement.classList.remove('fadeOutUp');
      self.addCompanyForm.nativeElement.classList.add('fadeInUp');

      self.listener1 = self.renderer.listen(self.addCompanyForm.nativeElement, 'transitionend', () => {
        self.removeBindingsLoad();
      });
      self.listener2 = self.renderer.listen(self.addCompanyForm.nativeElement, 'webkitTransitionEnd', () => {
        self.removeBindingsLoad();
      });
      self.listener3 = self.renderer.listen(self.addCompanyForm.nativeElement, 'animationend', () => {
        self.removeBindingsLoad();
      });
    }, 2000);
  }

  /* MBR0901-1401
  private companyError(error: Response | any) {

    // this.alertService.sendAlert(this.translation
    // .get("Er was een probleem bij het ophalen van de bedrijfsinformatie"), AlertTypes.WARNING, false);
    if (this.router.url === "/company-information/additional-companies") {
      this.router.navigate(['./company-information/additional']);
    } else {
      this.router.navigate(['./company-information/additional-companies']);
    }

    return this.appService.handleError(error);

  }
  */

  private doAddCompany(): void {
    const vat = this.companyNumber;
    const simulationID = this.appService.simulationNumber;
    if (vat === null || vat === undefined || vat === '') {
      this.router.navigate(['/risk-calculator']);
    }
    // let params = new URLSearchParams();
    // params.set('vatNumber', vat);
    // params.set('simulationId', simulationID);
    this.appService.catchError = false; // MBR0901-1401
    this.appService.errorText = ''; // MBR0901-1401

    const params = 'vatNumber=' + vat + '&simulationId=' + simulationID;

    this.http.post(environment.apiUrl + '/api/simulations/' + simulationID + '/companies?vatNumber=' + vat, params, this.authService.getHttpOptions())
      .catch((err) => this.appService.handleError(err)) // MBR0901-1401
      .subscribe((e) => {
        this.addCompanyResult = e;
        this.resultCode = this.addCompanyResult.Code;
        if (this.resultCode !== undefined) {
          if (this.resultCode === 200) {
            this.getCompanies(simulationID);
          } else {
            this.alertService.sendAlert(this.addCompanyResult.Error.Message, AlertTypes.WARNING, false);
          }
        } else {
          if (this.router.url === '/company-information/additional-companies') {
            this.router.navigate(['./company-information/additional']);
          } else {
            this.router.navigate(['./company-information/additional-companies']);
          }
        }
    });

    // this.companies.push(""); // MBR0901-1401
    this.companyNumber = '';
  }
  private getCompanies(simulationID: string): void {
    this.appService.catchError = false; // MBR0901-1401
    this.appService.errorText = ''; // MBR0901-1401
    this.http.get(environment.apiUrl + '/api/simulations/' + simulationID + '/companies', this.authService.getHttpOptions())
      .catch((err) => this.appService.handleError(err)) // MBR0901-1401
      .subscribe((f) => {
        this.companies = f;
    });
  }
  public addCompany(): void {
    /* MBR0901-1401
    this.hasError = false;
    this.hasErrorText = "";
    */
    this.doAddCompany();
    this.addBindingsLoad();
    this.loadingInformation.nativeElement.classList.add('fadeInUp');
    this.loadingInformation.nativeElement.classList.remove('fadeOutUp');
    this.addCompanyForm.nativeElement.classList.add('fadeOutUp');
  }
  public unloadPage(): void {
    const self = this;
    this.loadedHeader.nativeElement.classList.remove('fadeInUp');

    this.renderer.listen(this.loadedHeader.nativeElement, 'transitionend', () => {
      self.router.navigate(['./wizard/start']);
    });
    this.renderer.listen(this.loadedHeader.nativeElement, 'webkitTransitionEnd', () => {
      self.router.navigate(['./wizard/start']);
    });
    this.renderer.listen(this.loadedHeader.nativeElement, 'animationend', () => {
      self.router.navigate(['./wizard/start']);
    });
    this.loadedHeader.nativeElement.classList.add('fadeOut');
    this.companyInformation.nativeElement.classList.add('fadeOut');
  }
  @HostListener('document:keydown', ['$event'])
  private onKeyDown(event): void {
    const companyInput = document.getElementById('companyNumber');
    const isFocused = (document.activeElement === companyInput);
    if (event.key === 'Enter' && this.dataLoaded && !isFocused) {
      this.unloadPage();
    }
  }
}
