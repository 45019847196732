import { UserRoles } from './user-roles';
import { Outlet } from './outlet';

export class User {
    public id: number;
    public archived: boolean = false;
    public email: string;
    public name: string;
    public firstName: string;
    public role: UserRoles;
    public language: string;
    public gender: string;
    public password: string;
    public telephone: string;
    public newPassword: string;

}
