import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../app.service';
import { AuthService } from '../../auth/auth.service';

@Component({
    selector: 'app-okta',
    templateUrl: './okta.component.html',
    styleUrls: ['./okta.component.scss']
})
export class OktaComponent implements OnInit {

    constructor(
        private appService: AppService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.authService.testOkta()
            .subscribe(resp => {
                if (resp['redirectUrl'] !== '') {
                    window.location.href = resp['redirectUrl'];
                }
                console.log(resp);
            }, err => {
                console.log(err);
            });
    }

}
